import {Claim} from "@/store/modules/reclamation/types"
import moment from "moment"
import _ from "lodash"

function setAllValuesToNull(obj: any) {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      setAllValuesToNull(obj[key])
    } else {
      obj[key] = null
    }
  }
}

function matchTableData(target: any, source: any) {
  for (const eye in source) {
    source[eye].forEach((item: {title: string; value: string}) => {
      target[eye][item.title] = item.value
    })
  }
}

function matchZoneData(target: any, source: any) {
  source.forEach((item: {name: string; title: string; value: string}) => {
    target[item.name].isChecked = true
    if (_.has(target[item.name], "value")) {
      target[item.name].value = item.value
    }
  })
}

export default {
  setEditValues(_state: any, data: any) {
    const {
      refraction_order_lens_data,
      refraction_glass_data,
      refraction_lens_data,
      far_area,
      medium_area,
      near_area,
      client,
      frame_params,
      ...rest
    } = data

    const {frame_type, frame_type_value, ...restFrameparam} = frame_params

    _state.formGPM.replaceableLenses.frame_type = frame_params.frame_type
    _state.formGPM.replaceableLenses.frame_type_value =
      frame_params.frame_type_value

    for (const infoBlock in _state.formGPM) {
      if (infoBlock !== "lensComplaintAnalysis") {
        for (const key in _state.formGPM[infoBlock]) {
          if (
            key !== "refraction_order_lens_data" &&
            key !== "refraction_glass_data" &&
            key !== "refraction_lens_data" &&
            key !== "far_area" &&
            key !== "medium_area" &&
            key !== "client" &&
            key !== "frame_params" &&
            key !== "frame_type" &&
            key !== "frame_type_value"
          ) {
            _state.formGPM[infoBlock][key] = rest[key]
          }
        }
      }
    }
    matchZoneData(_state.formGPM.lensComplaintAnalysis.far_area, far_area)
    matchZoneData(_state.formGPM.lensComplaintAnalysis.medium_area, medium_area)
    matchZoneData(_state.formGPM.lensComplaintAnalysis.near_area, near_area)
    matchTableData(
      _state.formGPM.replaceableLenses.refraction_order_lens_data,
      refraction_order_lens_data
    )
    matchTableData(
      _state.formGPM.replaceableLenses.frame_params,
      restFrameparam
    )
    matchTableData(
      _state.formGPM.previousGlasses.refraction_lens_data,
      refraction_lens_data
    )
    matchTableData(
      _state.formGPM.previousGlasses.refraction_glass_data,
      refraction_glass_data
    )
  },

  clearGPMForm(_state: any) {
    const {lensComplaintAnalysis, ...rest} = _state.formGPM

    setAllValuesToNull(rest)

    for (const infoBlock in lensComplaintAnalysis) {
      for (const item in lensComplaintAnalysis[infoBlock]) {
        lensComplaintAnalysis[infoBlock][item].isChecked = false
        if (_.has(lensComplaintAnalysis[infoBlock][item], "value")) {
          lensComplaintAnalysis[infoBlock][item].value = null
        }
      }
    }
  },

  setErrors(_state: any, data: any) {
    if (Object.keys(data).length) {
      _state.errors[data.key] = data.value
    } else {
      _state.errors = {}
    }
  },

  setOrderConfirm(_state: any, confirm: boolean) {
    _state.orderConfirm = confirm
  },

  setReclamationList(_state: any, list: Claim[]) {
    if (_state.filters.GPK.pagination.current > 1) {
      _state.reclamationList = [..._state.reclamationList, ...list]
    } else {
      _state.reclamationList = list
    }
  },

  setStatuses(_state: any, data: any) {
    _state.filters[data.type].statuses = data.statuses
  },

  setNext(_state: any, data: any) {
    _state.filters[data.type].pagination.next = data.link
  },

  setCurrentPage(_state: any, data: any) {
    _state.filters[data.type].pagination.current = data.page
  },

  setFilterChecked(_state: any, data: any) {
    _state.filters[data.type].claimFiltersChecked[data.label] = data.value
    _state.filters[data.type].pagination.current = 1
  },

  setRange(_state: any, data: any) {
    _state.filters[data.type].range = data.value
    if (data.value && data.value.length) {
      const date_from = moment(data.value[0]._d).format("YYYY-MM-DD")
      const date_till = moment(data.value[1]._d).format("YYYY-MM-DD 23:59")

      _state.filters[data.type].claimFiltersChecked.created_before = date_till
      _state.filters[data.type].claimFiltersChecked.created_after = date_from
    } else {
      _state.filters[data.type].claimFiltersChecked.created_before = null
      _state.filters[data.type].claimFiltersChecked.created_after = null
    }
    _state.filters[data.type].pagination.current = 1
  },

  clearFilters(_state: any, type: string) {
    for (const key in _state.filters[type].claimFiltersChecked) {
      if (key === "claims_status") {
        _state.filters[type].claimFiltersChecked[key] = []
      } else {
        _state.filters[type].claimFiltersChecked[key] = null
      }
    }
    _state.filters[type].pagination.current = 1
    _state.filters[type].range = null
  },

  setReclamationGPMList(_state: any, list: any) {
    if (_state.filters.GPM.pagination.current > 1) {
      _state.reclamationGPMList = [..._state.reclamationGPMList, ...list]
    } else {
      _state.reclamationGPMList = list
    }
  },

  setOrdersRxShort(_state: any, list: any) {
    _state.orderRxList = list
  },

  setFormGPM(_state: any, data: any) {
    _state.formGPM[data.section][data.name] = data.value
  },

  setRefractionOrderLens(_state: any, data: any) {
    _state.formGPM.replaceableLenses.refraction_order_lens_data.re = data.re
    _state.formGPM.replaceableLenses.refraction_order_lens_data.le = data.le
  },

  setRefraction(_state: any, data: any) {
    _state.formGPM[data.section][data.name].re = data.recipe.re
    _state.formGPM[data.section][data.name].le = data.recipe.le
  },

  setLensCheckboxValue(_state: any, data: any) {
    const {area, checkboxName, value: checkboxValue} = data
    const currentCheckbox =
      _state.formGPM.lensComplaintAnalysis[area][checkboxName]
    currentCheckbox.isChecked = checkboxValue

    const hasValueKey = _.has(currentCheckbox, "value")
    _state.formGPMErrors[`${area}_${checkboxName}`] =
      hasValueKey && !!checkboxValue && !currentCheckbox.value
  },

  setLensInputValue(_state: any, data: any) {
    const {area, checkboxName, value} = data
    _state.formGPM.lensComplaintAnalysis[area][checkboxName].value = value
    _state.formGPMErrors[`${area}_${checkboxName}`] = !value
  },

  setLensRadioValue(_state: any, data: any) {
    const {area, checkboxName, value} = data
    _state.formGPM.lensComplaintAnalysis[area][checkboxName].value = value
    _state.formGPMErrors[`${area}_${checkboxName}`] = !value
  },

  setFrameParam(_state: any, data: any) {
    _state.formGPM.replaceableLenses.frame_params.frame_parameters_middle =
      data.middle
    _state.formGPM.replaceableLenses.frame_params.frame_parameters_rows_re =
      data.re
    _state.formGPM.replaceableLenses.frame_params.frame_parameters_rows_le =
      data.le
  },

  setFormGPMErrors(_state: any, data: any) {
    if (Object.keys(data).length) {
      _state.formGPMErrors[data.key] = data.value
    } else {
      _state.formGPMErrors = {}
    }
  },
}
