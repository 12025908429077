import { render, staticRenderFns } from "./RestorePasswordForm.vue?vue&type=template&id=30d41588&scoped=true&lang=pug&"
import script from "./RestorePasswordForm.vue?vue&type=script&lang=ts&"
export * from "./RestorePasswordForm.vue?vue&type=script&lang=ts&"
import style0 from "./RestorePasswordForm.vue?vue&type=style&index=0&id=30d41588&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30d41588",
  null
  
)

export default component.exports