import {apiClient} from "@/api/setupApi"

const BASE_API = "api/v1"

const BASE_VUE_APP_URL = process.env.VUE_APP_URL

const CART_GET_DELIVERY_ADDRESSES = `${BASE_API}/`
const CART_GET_DELIVERY_COMPANIES = `${BASE_API}/`
const CART_GET_ORDERS = `${BASE_VUE_APP_URL}/api/v1/cart/get-orders/`
const CART_UPDATE_ORDER = `${BASE_VUE_APP_URL}/order/api/v1/cart/update_finalize_order/`
const CART_SUBMIT_ORDER = `${BASE_VUE_APP_URL}/order/api/v1/cart/finalize_order/`
const CART_SUBMIT_ORDER_RX = `v1/cart/finalize_order/rx`
const CART_APPROVE_ORDER = `/v1/cart/approve-order/`
const NOMENCLATURE_EMPTY_GET_OLD_ORDERED = `/order/api/v1/orders/warehouse/last_orders/`
const CART_CREATE_GLASSES = (id: string) => `/api/v1/orders/warehouse/${id}/workshop`
const CART_DELETE_CREATE_GLASSES = (id: string) => `/api/v1/orders/warehouse/${id}/workshop`
const CART_DELETE_RX = 'v1/orders/warehouse/rx'
const CART_ITEMS_COUNT = 'v1/cart/orders/count'

export default {
  getOrders(rx: boolean) {
    return apiClient.get(`${CART_GET_ORDERS}${rx === true ? 'rx' : ''}`)
  },
  getDeliveryAddresses() {
    return apiClient.post(CART_GET_DELIVERY_ADDRESSES)
  },
  getDeliveryCompanies() {
    return apiClient.post(CART_GET_DELIVERY_COMPANIES)
  },
  updateOrder(data: object) {
    return apiClient.post(CART_UPDATE_ORDER, data)
  },
  submitOrder(data: object) {
    return apiClient.post(CART_SUBMIT_ORDER, data)
  },
  submitOrderRx(id: string) {
    return apiClient.post(CART_SUBMIT_ORDER_RX, { id })
  },
  approveOrder(data: object) {
    return apiClient.post(CART_APPROVE_ORDER, data)
  },
  approveOrderRX(id: string) {
    return apiClient.post(`${CART_APPROVE_ORDER}rx/${id}`)
  },
  getNomenclatureEmptyGetOldOrdered(data: object) {
    return apiClient.get(NOMENCLATURE_EMPTY_GET_OLD_ORDERED, {
      params: data,
      baseURL: BASE_VUE_APP_URL,
    })
  },
  createGlasses(id: string, data: object) {
    return apiClient.patch(CART_CREATE_GLASSES(id), data)
  },
  deleteWorkshop(id: string) {
    return apiClient.delete(CART_DELETE_CREATE_GLASSES(id))
  },
  deleteRXOrder(id: string) {
    return apiClient.delete(`${CART_DELETE_RX}/${id}`)
  },
  cartItemsCart() {
    return apiClient.get(CART_ITEMS_COUNT)
  }
}
