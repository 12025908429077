export default {
  gettersNomenclatureExtendedFilters: (_state: any) => {
    function fix(key: string) {
      return key === "Design"
        ? "design"
        : key === "Index"
        ? "indexes"
        : key === "Manufacturer"
        ? "manufacturers"
        : key === "Segment"
        ? "segment"
        : key === "SunProtect"
        ? "protection"
        : "coating"
    }

    const res = Object.assign(
      {},
      ...Object.keys(_state.nomenclatureExtendedFilters).map((key) => ({
        [fix(key)]: _state.nomenclatureExtendedFilters[key],
      }))
    )
    return res
  },
  getSegments: (_state: any) => {
    return _state.segments.data?.map((seg: any) => {
      const item = {
        id: seg.id,
        name: seg.name,
        visibilityType: {
          value: seg.type,
        },
        segments:
          seg.clients && seg.clients.length
            ? seg.clients?.map((client: any) => ({
                text: client.name,
                value: client.id,
              }))
            : [],
      }

      return item
    })
  },
}
