










import Vue from "vue"
import Icon from "@/components/ui/Icon.vue"

export default Vue.extend({
  name: "PageHeader",
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
    },
    hasBackButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      this.$emit("click")
    },
  },
})
