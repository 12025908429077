import api from "@/api/catalog"
import { localStorageService } from "@/services/localStorage";

export default {
  async getNomenclatureLens({commit}: any, data: any) {
    try {
      if (data.Stigmatic === "stigmatic") {
        return await api.getNomenclatureStigmaLens(data)
      }
      return await api.getNomenclatureAstigmaticLens(data)
      // return await api.getNomenclatureAstigmaLens(data)
    } catch (error) {
      return {error: "error"}
    }
  },
  async getNomenclatureAstigmaticLensRange({commit}: any, id: string) {
    try {
      return await api.getNomenclatureAstigmaticLensRange(id)
    } catch (error) {
      return {error: "error"}
    }
  },
  async getNomenclatureEmptyLenses({commit}: any, data: any) {
    try {
      const res = await api.getNomenclatureEmptyLenses(data)
      commit("setNomenclatureEmptyLenses", res)
      return res
    } catch (error) {
      return error
    }
  },
  async getNomenclatureExtendedFilters({commit}: any, data: object) {
    try {
      const res = await api.getNomenclatureExtendedFilters(data)
      commit("setNomenclatureExtendedFilters", res)
    } catch (error) {
      console.error("CATALOG NOMENCLATURE EXTEND GET FILTERS", error)
    }
  },
  async getNomenclatureExtendedLenses({commit}: any, data: any) {
    try {
      const {page, ...filters} = data
      const res = await api.getNomenclatureExtendedLenses({
        filters: filters,
        page: page,
      })
      return res
    } catch (error) {
      return {error: "error"}
    }
  },

  async NomenclatureGetBalanceByLenses({commit}: any, data: string) {
    try {
      return await api.NomenclatureGetBalanceByLenses(data)
    } catch (error) {
      return {error: "error"}
    }
  },
  async getSegments({commit}: any, data: any) {
    try {
      const res = await api.getSegments(data)
      commit("setSegments", res)
      return res.data
    } catch (error) {
      return {error}
    }
  },
  async saveSegments({commit}: any, data: any) {
    try {
      return await api.saveSegments(data)
    } catch (error) {
      return {error}
    }
  },
  async NomenclatureChangeCountLenses(context: any, data: any) {
    try {
      const localStorageItem = localStorageService.load('addToCart')
      const addToCart = localStorageItem && JSON.parse(localStorageItem)
      if (localStorageItem && addToCart.fromCart) {
        data.data.order = addToCart.order
      } else if (data.orders) {
        const orderNumber = data.orders.find((order: any) => order.delivery_point_guid === data.data.delivery_point_guid && !order.in_workshop)
        data.data.order = orderNumber && !orderNumber.confirmed ? orderNumber.id : null
      }
      return await api.NomenclatureChangeCountLenses(data.data)
    } catch (error) {
      return {error}
    }
  },
  async NomenclatureGetOrder(context: any, data: object) {
    try {
      return await api.NomenclatureGetOrder(data)
    } catch (error) {
      return {error: "error"}
    }
  },
  async NomenclatureGetAllOrders(context: any, data: object) {
    try {
      return await api.NomenclatureGetAllOrders(data)
    } catch (error) {
      return {error: "error"}
    }
  },
  async deleteOrder({dispatch}: any, order: object) {
    try {
      return await api.deleteOrder(order)
    } catch (error) {
      return {error: "error"}
    }
  },
  async repeatOrder({dispatch}: any, order: object) {
    try {
      return await api.repeatOrder(order)
    } catch (error) {
      return {error: "error"}
    }
  },
}
