












import Vue from "vue"

export default Vue.extend({
  name: "Auth",

  // props: {
  //   contentAlign: {
  //     type: String,
  //     default: () => 'bottom'
  //   },
  // },
})
