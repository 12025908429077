import {apiClient} from "@/api/setupApi"

import {OrderRXFiltersRequest} from "@/store/modules/orderRX/types"

const BASE_VUE_APP_URL = process.env.VUE_APP_URL
const GET_ORDERRX_FILTERS = `v1/catalog_rx/nomenclature`
const GET_ORDER_RX_LENSES = `v1/catalog_rx/nomenclature/filter`
const ADD_NEW_ORDER = `v1/orders/warehouse/rx`
const ORDER_RX_RULES = (id: string) => `v1/catalog_rx/nomenclature/${id}/rules`
const GET_COLOR_PALETTE = `v1/catalog_rx/palette`

export default {
  getOrderRXFilters(data: any) {
    const newData: any = {}
    Object.keys(data).forEach(key => {
      if (data[key]) newData[key] = data[key][0]
    })
    delete newData.type
    const params = {
      ...newData,
      type: 'Rx',
      page: data.page || 1,
    }
    return apiClient.get(`${GET_ORDERRX_FILTERS}`, { params });
  },
  getOrderRXLenses() {
    return apiClient.get(GET_ORDER_RX_LENSES)
  },
  postOrderRXLenses(data: any) {
    return apiClient.post(GET_ORDER_RX_LENSES, data)
  },
  deleteOrderRXLens(id: number) {
    return apiClient.delete(`${GET_ORDER_RX_LENSES}/${id}`)
  },
  addNewOrder(data: object) {
    return apiClient.post(ADD_NEW_ORDER, data)
  },
  getOrderRXRules(id: string) {
    return apiClient.get(ORDER_RX_RULES(id))
  },
  updateOrderRx(id: string, data: object) {
    return apiClient.put(`${ADD_NEW_ORDER}/${id}`, data)
  },
  getColorPalettes(guid_1c: string) {
    return apiClient.get(GET_COLOR_PALETTE, { params: { guid_1c } })
  }
}
