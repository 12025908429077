import {
  TableListMutation,
  PaginationTotalMutation,
  TableParamsMutation,
  CurrentPageMutation,
} from "@/store/modules/financialInforamation/types"

export default {
  setTableList(state: any, {tableName, data}: TableListMutation) {
    state[tableName].tableList = data
  },
  setPaginationTotal(state: any, {tableName, total}: PaginationTotalMutation) {
    state[tableName].pagination.total = total
  },
  setTableParams(state: any, {tableName, ordering}: TableParamsMutation) {
    state[tableName].ordering = ordering
    state[tableName].pagination.current = 1
  },
  setCurrentPage(state: any, {tableName, page}: CurrentPageMutation) {
    state[tableName].pagination.current = page
  },
}
