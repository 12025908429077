







import Vue from "vue"
import {mapActions} from "vuex"
import Auth from "@/views/Auth/components/Auth.vue"
import PasswordCreationForm from "@/components/forms/PasswordCreationForm.vue"
import {Credentials} from "@/views/Auth/models/auth"

export default Vue.extend({
  name: "Register",

  components: {
    PasswordCreationForm,
    Auth,
  },

  methods: {
    ...mapActions("auth", ["create"]),

    createNewPassword(data: Credentials) {
      this.create({password: data, hash: this.$route.params.id}).then(() =>
        this.$router.push("/")
      )
    },
  },
})
