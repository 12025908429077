export default {
  client: {},
  clients: [],
  availableClients: [],
  clientsCollection: [],
  managersCollection: null,
  pointsCollection: null,
  clientsPagination: {
    total: "",
    page: "",
    countItem: 10,
  },

  employees: {
    data: [],
    meta: {
      total: 7,
      page: 1,
      records_on_page: 10,
    },
  },
  deliveryPointsShort: [],
  deliveryPoints: {
    data: [],
    meta: {
      total: 7,
      page: 1,
      records_on_page: 10,
    },
  },
  changedAccount: {},
  leaders: [],
}
