import {localStorageService} from "@/services/localStorage"
import {parseJWT} from "@/utils/parseJWT"

type AuthResponse = {
  access_token: string
  access: string
  exp: string
  refresh_token: string
  refresh: string
  permissions: object
  user_data: object
}

export default {
  authRequest(_state: any) {
    _state.status = "loading"
  },
  authSuccess(_state: any, data: AuthResponse) {
    _state.status = "success"
    _state.access = data.access_token
    _state.expires = parseJWT(data.access_token).getExp
    _state.refresh = data.refresh_token
    _state.permissions = parseJWT(data.access_token).getPermissions
    _state.user = {
      ...data,
      role_name: parseJWT(data.access_token).getRole,
      client_guid: parseJWT(data.access_token).getClientId,
      is_mok_emp: parseJWT(data.access_token).getIsMokEmp,
    }
  },
  authSuccessCopy(_state: any, data: AuthResponse) {
    _state.authCopy = {
      status: "success",
      access: data.access_token,
      expires: data.exp,
      refresh: data.refresh_token,
      permissions: parseJWT(data.access_token).getPermissions,
      user: {
        ...data,
        role_name: parseJWT(data.access_token).getRole,
        is_mok_emp: parseJWT(data.access_token).getIsMokEmp,
      },
    }
  },
  changeAuth(_state: any, data: any) {
    localStorageService.save({key: "AUTH_TOKEN", value: _state.access})
    _state.status = _state.authCopy.status
    _state.access = _state.authCopy.access
    _state.expires = _state.authCopy.expires
    _state.refresh = _state.authCopy.refresh
    _state.permissions = _state.authCopy.permissions
    _state.user = _state.authCopy.user
  },
  setChangedAccount(_state: any, data: any) {
    _state.changedAccount = data
  },
  authError(_state: any) {
    _state.status = "error"
  },
  logout(_state: any) {
    _state.status = ""
    _state.access = ""
    _state.refresh = ""
    _state.authCopy = {}
  },
  setError(_state: any, value: boolean) {
    _state.error = value
  },
  setErrorText(_state: any, value: string) {
    _state.errorText = value
  },
  completeUser(_state: any, value: any) {
    if (value.id === _state.user.id) {
      const user = {
        ..._state.user,
        user_pic: value.img,
        lastname: value.lastname,
        middlename: value.middlename,
        name: value.name,
        phone: value.phone,
        email: value.email,
        cyl_state: value.cyl_state
      }
      _state.user = ""
      _state.user = user
    }
  },
}
