import {RouteConfig} from "vue-router"

import {PRIV} from "@/router/rights"

const routes: Array<RouteConfig> = [
  // {
  //   path: "/employees/:id",
  //   name: "Clients",
  //   component: () => import("@/views/Employees/Employees.vue"), // сюда надо подставить компонент Детали сотрудника
  //   props: (route) => ({
  // 		id: route.params.id || 0 // проверь это
  // 	}),
  //   meta: {
  //
  //   }
  // },
  {
    path: "/employees/list",
    name: "EmployeesList",
    component: () => import("@/views/Employees/List/List.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.client_adm],
      layout: "home",
      title: "Список сотрудников",
    },
  },
  {
    path: "/employees/subdivision",
    component: () => import("@/views/Employees/Subdivision/Subdivision.vue"),
    name: "Subdivisions",
    meta: {
      requiresAuth: true,
      flags: [PRIV.client_adm],
      layout: "home",
    },
  },
]

export default routes
