import {apiClient} from "@/api/setupApi"

const BASE_API = "v1/finance"

const GET_FINANCIAL_INFORMATION = `${BASE_API}/client`
const GET_SHIPMENT_PAYMENT_INFORMATION = `${BASE_API}/shipment-payment`
const GET_PAYMENT_SCHEDULE_INFORMATION = `${BASE_API}/payment-schedule`
const GET_DISCOUNT_INFORMATION = `${BASE_API}/discount`
const GET_ACT_OF_RECONCILIATION = `${BASE_API}/client/act`

interface FilterParams {
  ordering: string
  page: 1
}

export default {
  getFinancialInformation(): Promise<any> {
    return apiClient.get(GET_FINANCIAL_INFORMATION)
  },
  getShipmentPaymentInformation(params: FilterParams): Promise<any> {
    return apiClient.get(GET_SHIPMENT_PAYMENT_INFORMATION, {params})
  },
  getPaymentScheduleInformation(params: FilterParams): Promise<any> {
    return apiClient.get(GET_PAYMENT_SCHEDULE_INFORMATION, {params})
  },
  getDiscountInformation(params: FilterParams): Promise<any> {
    return apiClient.get(GET_DISCOUNT_INFORMATION, {params})
  },
  getActOfReconciliation(): Promise<any> {
    return apiClient.post(GET_ACT_OF_RECONCILIATION)
  },
}
