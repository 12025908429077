import api from "@/api/financialInformation"

export default {
  async getFinancialInformation() {
    const result = await api.getFinancialInformation()
    return result
  },
  async getShipmentPaymentInformation({commit, state}: any) {
    const result = await api.getShipmentPaymentInformation({
      ordering: state.shipmentPaymentInformation.ordering,
      page: state.shipmentPaymentInformation.pagination.current,
    })
    commit("setTableList", {
      tableName: "shipmentPaymentInformation",
      data: result.results,
    })
    commit("setPaginationTotal", {
      tableName: "shipmentPaymentInformation",
      total: result.count,
    })
    return result
  },

  async getPaymentScheduleInformation({commit, state}: any) {
    const result = await api.getPaymentScheduleInformation({
      ordering: state.paymentScheduleInformation.ordering,
      page: state.paymentScheduleInformation.pagination.current,
    })
    commit("setTableList", {
      tableName: "paymentScheduleInformation",
      data: result.results,
    })
    commit("setPaginationTotal", {
      tableName: "paymentScheduleInformation",
      total: result.count,
    })
    return result
  },

  async getDiscountInformation({commit, state}: any) {
    const result = await api.getDiscountInformation({
      ordering: state.discountInformation.ordering,
      page: state.discountInformation.pagination.current,
    })
    commit("setTableList", {
      tableName: "discountInformation",
      data: result.results,
    })
    commit("setPaginationTotal", {
      tableName: "discountInformation",
      total: result.count,
    })

    return result
  },
  async getActOfReconciliation() {
    const result = await api.getActOfReconciliation()
    return result
  },
}
