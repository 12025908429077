

















import Vue from "vue"
import {mapActions} from "vuex"
import Auth from "@/views/Auth/components/Auth.vue"
import BackToLogin from "@/views/Auth/components/BackToLogin.vue"
import RestorePasswordForm from "@/components/forms/RestorePasswordForm.vue"

export default Vue.extend({
  name: "Restore",

  data() {
    return {
      successfulReset: false,
      email: "",
      windowHeight: window.innerHeight,
    }
  },

  components: {
    RestorePasswordForm,
    Auth,
    BackToLogin,
  },

  methods: {
    ...mapActions("auth", ["restore"]),

    resetPassword(email: string) {
      this.restore(email).then(() => {
        this.successfulReset = true
        this.email = email
      })
    },
  },
})
