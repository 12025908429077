<template lang="pug">
  a-layout.container
    Header.header
    .background(v-if="!collapsed" @click="collapse")
    a-layout-sider.sider(
      breakpoint="xl"
      collapsed-width="0"
      :zeroWidthTriggerStyle="{'top':  '11px', 'background': '#005BAA'}"
      width=250
      v-model="collapsed"
    )
      div.logo
        img.logo__img(alt="Logo", src="../assets/img/Logo_MOK.webp")

      a-menu(
        style="overflow: hidden, overflowY: auto",
        :default-open-keys="defaultOpenKeys",
        mode="inline"
      )
        a-menu-item.item(
          v-if=""
          key="home"
        )
          span
            a-icon(type="home")
            span Главная
          router-link(to="/")

        a-menu-item.item(
          key="warehouse-lenses"
          v-if="isWarehouseOrderAccess"
        )
          span
            a-icon(type="appstore")
            span Заказ склад. линз
          router-link(to="/catalog-assortment")

        a-menu-item.item(
          key="orderRX"
          v-if="permissions.view_nomenclaturesrx"
        )
          span
            a-icon(type="file-text")
            span Заказ рецепт. линз
          router-link(to="/orderRX/choice-lenses/id")

        a-sub-menu.item(
          key="employees"
          v-if="user.role_name === 'mok_tech_administrator' || user.role_name === 'mok_1c_administrator'"
        )
          span(slot="title")
            a-icon(type="team")
            span.item Сотрудники
          a-menu-item.item(
            v-if="permissions.view_group"
            @click="changeCurrentPath('/employees/list')"
          ) Список
            router-link(to='/employees/list')

          a-menu-item.item(
            v-if="permissions.view_subdivision"
            @click="changeCurrentPath('/employees/subdivision')"
          ) Подразделения
            router-link(to='/employees/subdivision')


        a-menu-item.item(
          key="roles"
          v-if="permissions.view_group"
          @click="changeCurrentPath('/employees/roles')"
        )
          span
            a-icon(type="control")
            span Роли
          router-link(to="/roles")

        a-sub-menu.item(
          key="clients"
          v-if="isClientsAccess"
        )
          span(slot="title")
            a-icon(type="shop")
            span Клиенты
          a-menu-item.item(
            v-if="permissions.view_clients && permissions.client_adm_full"
            @click="changeCurrentPath('/clients/list')"
          ) Список
            router-link(to='/clients/list')

          a-menu-item.item(
            v-if="permissions.view_clients && user.is_mok_emp"
            @click="changeCurrentPath('/clients/employees')"
          ) Сотрудники
            router-link(to='/clients/employees')

          a-menu-item.item(
            v-if="permissions.view_deliverypoint"
            @click="changeCurrentPath('/clients/delivery-points')"
          ) Салоны оптики
            router-link(to='/clients/delivery-points')


        a-menu-item.item(
          key="segments"
          v-if="user.role_name === 'mok_tech_administrator' || user.role_name === 'mok_1c_administrator'"
        )
          span
            a-icon(type="unordered-list")
            span Сегменты
          router-link(to="/catalog-segments")



        a-menu-item.item(
          key="history-orders"
          v-if="isHistoryOrderAccess"
        )
          span
            a-icon(type="unordered-list")
            span История заказов
          router-link(to="/history-orders")

        a-sub-menu.item(
          key="marketingPromotions"
          v-if="permissions.view_promotions || permissions.view_promocodes"
        )
          span(slot="title")
            a-icon(type="star")
            span.item Маркетинговые акции
          a-menu-item.item(
            v-for="item in marketingPromotions", :key="item.route"
            v-if="permissions[item.view]"
            @click="changeCurrentPath(item.route)"
          ) {{ item.name }}
            router-link(:to="item.route")

        a-menu-item.item(
          key="/marketing-docs"
          v-if="permissions.view_materials"
        )
          span
            a-icon(type="book")
            span Маркетинговые материалы
          router-link(to="/marketing/materials")

        a-menu-item.item(
          key="event-history"
          v-if="permissions.view_eventhistory"
        )
          span
            a-icon(type="book")
            span Логирование
          router-link(to="/event-history")

        a-menu-item.item(
          key="/fin-info"
          v-if="permissions.view_financeclient"
        )
          span
            a-icon(type="dollar")
            span Финансовая информация
          router-link(to="/financial-information")

        a-menu-item.item(
          key="/analytics"
          v-if="permissions.view_financeclientanalytics"
        )
          span
            a-icon(type="bar-chart")
            span Аналитика по заказам
          router-link(to="/order-analytics")
          
        a-sub-menu.item(
          key="reclamation"
          v-if="permissions.add_feedback"
        )
          span(slot="title")
            a-icon(type="message")
            span Обращения
          a-menu-item.item(
            v-for="item in reclamation", :key="item.route"
            @click="changeCurrentPath(item.route)"
            ) {{ item.name }}
            router-link(:to="item.route")
        a-menu-item.item(
          key="/contacts"
          v-if="user.role_name === 'client_emploee' || user.role_name === 'client_leader' || user.role_name === 'client_test_rx_order' || user.role_name === 'client_new_user'"
        )
          span
            a-icon(type="contacts")
            span Контакты
          router-link(to="/contacts")

        a-menu-item.item(
          key="/notifications"
          v-if="permissions.view_notifications && permissions.add_notifications && permissions.delete_notifications"
        )
          span
            a-icon(type="bell")
            span Уведомления
          router-link(to="/notifications")

    a-layout.custom-layout(:class="{active: collapsed}")
      a-layout-content
        a-row.account-info(v-if="changedAccount")
          div.info-title Вы зашли под аккаунтом: {{ accountName }}
          a-button.quit-button(
            @click="backToAuthorizedAccount"
          ) Выйти
        router-view.main.INNER_CONTENT(:class="{changedAccount: changedAccount}")
</template>

<script>
import Vue from "vue"
import Header from "@/components/Header.vue"
import {mapGetters, mapMutations, mapActions, mapState} from "vuex"
import {checkAvailable} from "@/mixins/checkAvailable"

export default Vue.extend({
  name: "HomeLayout",
  mixins: [checkAvailable],

  components: {Header},

  data() {
    return {
      collapsed: false,
      defaultOpenKeys: ["/"],
      employees: [
        {
          name: "Список",
          rout: "/employees/list",
          view: "view_group",
        },
        {
          name: "Подразделения",
          rout: "/employees/subdivision",
          view: "view_subdivision",
        },
      ],
      // clients: [
      //   {
      //     name: "Список",
      //     rout: "/clients/list",
      //     view: "view_clients",
      //   },
      //   {
      //     name: "Сотрудники",
      //     rout: "/clients/employees",
      //     view: "view_clients",
      //   },
      //   {
      //     name: "Салоны оптики",
      //     rout: "/clients/delivery-points",
      //     view: "view_deliverypoint",
      //   },
      // ],
      marketingPromotions: [
        {
          name: "Акции",
          route: "/marketing/promotions",
          view: "view_promotions",
        },
        {
          name: "Бонусные коды",
          route: "/marketing/promo-codes",
          view: "view_promocodes",
        },
      ],
      reclamation: [
        {
          name: "Оформить обращение",
          route: "/reclamation/create",
        },
        {
          name: "Список обращений",
          route: "/reclamation/history",
        },
      ],
    }
  },
  updated() {
    this.selectedKeys = this.$route.path
  },
  computed: {
    ...mapGetters("auth", {permissions: "getPermissions"}),
    ...mapState("auth", ["user", "changedAccount"]),
    accountName() {
      return this.changedAccount && this.user
        ? `${this.user.lastname} ${this.user.name} ${this.user.middlename} `
        : ""
    },
    isWarehouseOrderAccess() {
      return this.checkAvailability("isAccessToOrderingStockLenses")
    },
    isClientsAccess() {
      return this.permissions.view_clients && !this.permissions.client_adm_none
    },
    isWarehouseAccess() {
      return this.checkAvailability("isAccessToCatalog")
    },
    isHistoryOrderAccess() {
      return this.checkAvailability("isAccessHistoryOrder")
    },
  },

  methods: {
    ...mapMutations("auth", ["changeAuth", "setChangedAccount"]),
    ...mapMutations(["setOwnCurrentPath"]),
    ...mapActions("clients", ["getClientEmployeesList"]),
    collapse() {
      this.collapsed = !this.collapsed
    },
    backToAuthorizedAccount() {
      this.setChangedAccount(false)
      this.changeAuth()

      const auth = JSON.parse(localStorage.getItem("vuex")).auth
      localStorage.setItem("AUTH_TOKEN", auth.access)
      localStorage.setItem("REFRESH_TOKEN", auth.refresh)
      window.location = window.location.href
    },

    changeCurrentPath(val) {
      this.setOwnCurrentPath(val)
      setTimeout(() => {
        this.setOwnCurrentPath("")
      }, 300)
    },
  },
})
</script>

<style lang="scss" scoped>
.logo {
  height: 64px;
  display: none;

  @media screen and (max-width: 500px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;

    .logo__img {
      width: 100%;
      height: auto;
    }
  }
}

.drawer_bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}

.container {
  padding-top: 64px;
  height: 100vh;
  position: relative;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
}

.menu {
  width: 224px;
}

.item {
  text-align: start;
}

.account-info {
  background-color: #005baa;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  padding: 0 24px;

  .info-title {
    line-height: 1.3;
    color: #fff;
    max-width: 74%;
  }

  .quit-button {
    position: absolute;
    right: 24px;
    margin-left: 24px;
  }
}

.custom-layout {
  margin-left: 250px;

  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }

  @media screen and (max-width: 768px) {
    margin-top: 64px;
  }

  &.active {
    margin-left: 0;
  }

  .main {
    animation: opacity 0.3s;
    height: calc(100vh - 64px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 24px;

    @media screen and (max-width: 576px) {
      padding: 24px 10px;
    }
  }
  .main.changedAccount {
    height: calc(100vh - 104px);
  }
}

.sider {
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 50;
  background: #fff;

  @media screen and (max-width: 1200px) and (min-width: 768px) {
    top: 0;
  }
}

.ant-menu {
  border-right-width: 0;
  padding-top: 20px;

  .anticon {
    color: var(--blue-gray);
    transition: color 0.15s ease-in-out;
  }

  &:not(.ant-menu-horizontal) {
    .ant-menu-item-selected,
    .ant-menu-item-active {
      background-color: #f2f7fb;

      .anticon {
        color: currentColor;
      }
    }

    .ant-menu-submenu-open {
      .anticon {
        color: currentColor;
      }
    }
  }
}

.background {
  display: none;

  @media screen and (max-width: 1200px) {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 50;
  }
}
</style>
