export const checkAvailable = {
  methods: {
    checkAvailability(field: string) {
      if (!(this as any).permissions) {
        return false
      }

      switch (field) {
        case "isAccessToOrderingStockLenses":
          return (
            (this as any).permissions.view_orders &&
            // (this as any).permissions.view_ordersrx &&
            (this as any).permissions.view_nomenclaturespecifications &&
            (this as any).permissions.view_nomenclatures &&
            (this as any).permissions.view_segments &&
            (this as any).permissions.view_orderrecipes
          )

        case "isAccessToCatalog":
          return (
            (this as any).permissions.view_nomenclatures &&
            (this as any).permissions.view_segments
          )

        case "isAccessHistoryOrder":
          return (
            //  (this as any).permissions.view_subdivision &&
            (this as any).permissions.view_deliverypoint &&
            (this as any).permissions.view_orderrecipes
          )

        default:
          return false
      }
    },
  },
}
