import {apiClient} from "@/api/setupApi"

const BASE_API = "v1/promotions"
const GET_PROMO_CODES = "v1/promocodes"

export default {
  getPromotions(): Promise<any> {
    return apiClient.get(`${BASE_API}`)
  },
  getPromotionTerms(promotionId: string): Promise<any> {
    return apiClient.get(`${BASE_API}/${promotionId}/terms`)
  },
  acceptOffer(promotionId: string): Promise<any> {
    return apiClient.post(`${BASE_API}/${promotionId}/accepted`)
  },
  generateMaterialPdf(materialId: string): Promise<any> {
    return apiClient.post(`${BASE_API}/generate-material-pdf/${materialId}`)
  },
  generateOfferPdf(promotionId: string): Promise<any> {
    return apiClient.post(`${BASE_API}/${promotionId}/generate-offer-pdf`)
  },
  getPromoCodes(userId: string): Promise<any> {
    return apiClient.get(`${GET_PROMO_CODES}?user_guid=${userId}`)
  },
}
