// @ts-nocheck

import api from "@/api/orderRX"
import apiLensPicker from "@/api/lensPicker"
import {OrderRXFiltersRequest} from "@/store/modules/orderRX/types"

export default {
  async getOrderRXFilters({commit}: any, data: OrderRXFiltersRequest) {
    try {
      const res = await api.getOrderRXFilters(data)
      commit("setOrderRXFilters", {
        currentPage: data.page,
        res,
      })
      return res
    } catch (error) {
      return error
    }
  },

  async checkRecipeParameters({commit, state}: any ) {
    const reduceArrayToParams = (array) => {
      return array.reduce((result, item) => {
        const value = item.value !== "" ? item.value : null
        result[item.title] = value
        return result
      }, {})
    }

    const recipeDetails = {
      le: {...reduceArrayToParams(state.order.recipeDetails.recipeDetailsTableLE), dia: Number(state.order.diameter)},
      re: {...reduceArrayToParams(state.order.recipeDetails.recipeDetailsTableRE), dia: Number(state.order.diameter)},
    }

    const data = {
      filter_params: {
        ...recipeDetails,
      },
    }

    const result = await apiLensPicker.postRecipeParameters(data)
    return result
  },

  async addNewOrder({commit}: any, data: object) {
    try {
      return await api.addNewOrder(data)
    } catch (error) {
      return {error: error.response.data}
    }
  },

  async updateOrderRx({commit}: any, {id, data}) {
    try {
      return await api.updateOrderRx(id, data)
    } catch (error) {
      return {error: error.response.data}
    }
  },

  async getColorPalettes({commit}: any, id: string){
    try {
      return await api.getColorPalettes(id)
    } catch (error) {
      return {error: error.response.data}
    }
  },

  async getOrderRXRules({commit}: any, id: string) {
    try {
      const res = await api.getOrderRXRules(id)
      commit("setErrors", {})
      commit("setOrderRXRules", res);
      return res
    } catch (e) {
      commit("setOrderRXRules", {});
      return e
    }
  },

  async getOrderRXLenses({commit}: any) {
    try {
      const res = await api.getOrderRXLenses()
      const initialFilter = [
        {
          type: "l",
          manufacture: [],
          style: "",
          index: [],
          design: "",
          property: null,
          tint: null,
          color: "",
          // search_string: "",
        },
        {
          type: "r",
          manufacture: [],
          style: "",
          index: [],
          design: "",
          property: null,
          tint: null,
          color: "",
          // search_string: "",
        }
      ]
      if (!res.length) {
        commit("setInitialOrderRXFiltersChecked", initialFilter)
        commit("setChosenLens", [{ type: 'l' }, { type: 'r' }])
        commit("setLens", [{ type: 'l' }, { type: 'r' }])
        commit("setFilterId", [{}, {}])
        commit("setLensParameters", { key: 'coloring_l', data: '' })
        commit("setLensParameters", { key: 'coloring_r', data: '' })
      } else {
        const leftLens = res.find(lens => lens.nomenclature_type === 'l')
        const rightLens = res.find(lens => lens.nomenclature_type === 'r')
        const filtersChecked = []
        const chosenLens = []
        const filtersIds = []
        if (leftLens) {
          filtersChecked.push({ ...leftLens.query_params.find(el => el.type === 'l'), type: "l" });
          chosenLens.push({ ...leftLens.nomenclature_rx, type: "l" });
          filtersIds.push({ type: "l", id: leftLens.id });
          commit("setLensParameters", { key: 'coloring_l', data: leftLens.item_data.coloring_l })
        } else {
          filtersChecked.push(initialFilter[0]);
          chosenLens.push({ type: "l" });
          filtersIds.push({ type: "l" });
          commit("setLensParameters", { key: 'coloring_l', data: '' })
        }
        if (rightLens) {
          filtersChecked.push({ ...rightLens.query_params.find(el => el.type === 'r'), type: "r" });
          chosenLens.push({ ...rightLens.nomenclature_rx, type: "r" });
          filtersIds.push({ type: "r", id: rightLens.id });
          commit("setLensParameters", { key: 'coloring_r', data: rightLens.item_data.coloring_r })
        } else {
          filtersChecked.push(initialFilter[1]);
          chosenLens.push({ type: 'r' });
          filtersIds.push({ type: "r" });
          commit("setLensParameters", { key: 'coloring_r', data: '' })
        }
        commit("setInitialOrderRXFiltersChecked", filtersChecked)
        commit("setChosenLens", chosenLens)
        commit("setLens", chosenLens)
        commit("setFilterId", filtersIds)
      }
      return res
    } catch (error) {
      return {error: error.response.data}
    }
  },

  async deleteOrderRXLenses({commit}: any, id: number) {
    try {
      const res = await api.deleteOrderRXLens(id)
      commit("setInitialOrderRXFiltersChecked", [
        {
          type: "l",
          manufacture: [],
          style: "",
          index: [],
          design: "",
          property: null,
          tint: null,
          color: "",
          // search_string: "",
        },
        {
          type: "r",
          manufacture: [],
          style: "",
          index: [],
          design: "",
          property: null,
          tint: null,
          color: "",
          // search_string: "",
        }
      ])
      commit("setChosenLens", [{ type: 'l' }, { type: 'r' }])
      commit("setLens", [{ type: 'l' }, { type: 'r' }])
      commit("setFilterId", [{}, {}])
      commit("setLensParameters", { key: 'coloring_l', data: '' })
      commit("setLensParameters", { key: 'coloring_r', data: '' })
      return res
    } catch (error) {
      return {error: error.response.data}
    }
  },

  async postOrderRXLenses({commit}: any, data: object) {
    try {
      return await api.postOrderRXLenses(data)

    } catch (error) {
      return {error: error.response.data}
    }
  },
}
