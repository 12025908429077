import api from "@/api/marketing"
import {now} from "moment"

export default {
  async getPromotions({commit}: any) {
    const result = await api.getPromotions()
    commit("setPromotions", result)
    return result
  },
  async getPromotionTerms({commit}: any, promotionId: string) {
    const result = await api.getPromotionTerms(promotionId)
    commit("setPromotionTerms", result)
    return result
  },
  async acceptOffer({commit}: any, promotionId: string) {
    const result = await api.acceptOffer(promotionId)

    return result
  },
  async generateMaterialPdf({commit}: any, materialId: string) {
    const result = await api.generateMaterialPdf(materialId)

    return result
  },
  // Promocodes
  async getPromoCodes({commit}: any, id: string) {
    const result = await api.getPromoCodes(id)

    commit("setPromoCodes", result)
    return result
  },
  selectPromoCode({commit}: any, data: object) {
    commit("setSelectedPromoCodes", data)
  },
  cancelPromoCode({commit}: any, promoCodeId: string) {
    commit("removePromoCode", promoCodeId)
  },
}
