import {RouteConfig} from "vue-router"

import {PRIV} from "@/router/rights"

const routes: Array<RouteConfig> = [
  {
    path: "/clients/list",
    name: "ClientsList",
    component: () => import("@/views/Clients/List/Table/Table.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.client_adm],
      layout: "home",
    },
  },
  {
    path: "/clients/item/:id",
    name: "Clients",
    component: () => import("@/views/Clients/List/Item/Item.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.client_adm],
      layout: "home",
    },
  },
  {
    path: "/clients/employee/:id",
    name: "ClientEmployee",
    component: () => import("@/views/Clients/List/ClientEmployee.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.client_adm],
      layout: "home",
    },
  },
  {
    path: "/clients/employees",
    component: () => import("@/views/Clients/Employees/Employees.vue"),
    name: "Employees",
    meta: {
      requiresAuth: true,
      flags: [PRIV.client_adm],
      layout: "home",
    },
  },
  {
    path: "/clients/delivery-points",
    component: () => import("@/views/Clients/Points/Points.vue"),
    name: "Delivery-Points",
    meta: {
      requiresAuth: true,
      flags: [PRIV.client_adm],
      layout: "home",
    },
  },
]

export default routes
