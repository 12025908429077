import {RouteConfig} from "vue-router"

const routes: Array<RouteConfig> = [
  {
    path: "/reclamation/create",
    name: "reclamation-create",
    component: () => import("@/views/Feedback/ReclamationCreate.vue"),
    meta: {
      requiresAuth: true,
      flags: [],
      layout: "home",
      title: "Оформить обращение",
    },
  },
  {
    path: "/reclamation/edit/:id",
    name: "reclamation-edit",
    component: () => import("@/views/Feedback/ReclamationEdit.vue"),
    meta: {
      requiresAuth: true,
      flags: [],
      layout: "home",
      title: "Редактировать обращение",
    },
  },
  {
    path: "/reclamation/history",
    name: "reclamation-history",
    component: () => import("@/views/Feedback/ReclamationHistory.vue"),
    meta: {
      requiresAuth: true,
      flags: [],
      layout: "home",
      title: "Список обращений",
    },
  },
]

export default routes
