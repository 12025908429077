import api from "@/api/lensPicker"
import {CheckedFilters, OrderData} from "@/store/modules/lensPicker/types"

function mappedFilters(filters: CheckedFilters) {
  return Object.keys(filters).reduce((acc: any, key: string) => {
    const values = filters[key as keyof CheckedFilters]
    if (values.length) {
      acc[key] = values[0]
    }
    return acc
  }, {} as {[key: string]: string})
}

export default {
  async postRecipeParameters({commit, state}: any) {
    const data = {
      filter_params: {
        ...state.recipeDetails,
        ...mappedFilters(state.lensFiltersChecked),
      },
    }

    const result = await api.postRecipeParameters(data)
    commit("setLensFilters", result.filter_data)
    commit("setLensList", result.result)
    commit("setShowStepTwo", true)

    return result
  },

  async uploadLenspickerCatalog({_commit}: any, file: any) {
    const result = await api.uploadLenspickerCatalog(file)
    return result
  },

  async orderLenses({_commit, state}: any, orderData: OrderData) {
    const {addressSelected, deliveryPointSelected, lensSelected, stock_rx} =
      orderData

    const data = {
      filter_params: {
        ...state.recipeDetails,
        ...mappedFilters(state.lensFiltersChecked),
        stock_rx,
      },
      delivery_point_guid: deliveryPointSelected,
      nomenclature_guid: lensSelected,
      address: addressSelected,
    }

    const result = await api.orderLenses(data)

    return result
  },
}
