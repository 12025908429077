import Auth from "@/views/Auth/route"
import Employees from "@/views/Employees/routes"
import Clients from "@/views/Clients/routes"
import MarketingPromotionsRoutes from "@/views/Marketing/routes"
import ReclamationRouts from "@/views/Feedback/routes"

import Vue from "vue"
import VueRouter, {RouteConfig} from "vue-router"

import ContactsDetail from "@/views/Contacts/ContactsDetail.vue"
import {localStorageService} from "@/services/localStorage"
import {PRIV} from "./rights"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  ...Auth,
  ...Clients,
  ...Employees,
  ...MarketingPromotionsRoutes,
  ...ReclamationRouts,
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home/Home.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.acc_main_page],
      layout: "home",
    },
  },
  {
    path: "/roles",
    component: () => import("../views/Employees/Roles/Roles.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.emp_adm],
      layout: "home",
    },
  },
  {
    path: "/catalog-assortment",
    name: "Assortment",
    component: () => import("../views/Catalog/Assortment/Assortment.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.acc_directory],
      layout: "home",
    },
  },
  {
    path: "/catalog-lens/:id",
    name: "Lens",
    component: () => import("../views/Catalog/Assortment/Lens/Lens.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.acc_directory],
      layout: "home",
    },
  },
  {
    path: "/catalog-segments",
    name: "Segment",
    component: () => import("../views/Catalog/Segments/Segments.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.acc_directory],
      layout: "home",
    },
  },
  {
    path: "/orderRX/choice-lenses/:id",
    name: "ChoiceLenses",
    component: () => import("../views/OrderRX/ChoiceLenses.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.acc_orders_rx],
      layout: "home",
    },
  },
  {
    path: "/orderRX/choice-lens/:side/:id",
    name: "ChoiceLens",
    component: () => import("../views/OrderRX/ChoiceLens.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.acc_orders_rx],
      layout: "home",
    },
  },
  {
    path: "/orderRX/choice-color",
    name: "ChoiceColor",
    component: () => import("../views/OrderRX/components/ChoiceColor.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.acc_orders_rx],
      layout: "home",
    },
  },
  {
    path: "/orderRX/choice-coloring/:side/:id/:color_palette",
    name: "ChoiceColoring",
    component: () => import("../views/OrderRX/components/ChoiceColoring.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.acc_orders_rx],
      layout: "home",
    },
  },
  {
    path: "/orderRX/choice-mirror-coating",
    name: "ChoiceMirrorCoating",
    component: () =>
      import("../views/OrderRX/components/ChoiceMirrorCoating.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.acc_orders_rx],
      layout: "home",
    },
  },
  {
    path: "/lens-picker",
    name: "lens-picker",
    component: () =>
      import("@/views/LensPicker/LensPickerForm.vue"),
    meta: {
      requiresAuth: true,
      flags: [],
      layout: "home",
      title: "Подбор линз",
    },
  },
  {
    path: "/history-orders",
    name: "HistoryOrders",
    component: () => import("@/views/HistoryOrders/HistoryOrders.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.acc_order_history],
      layout: "home",
    },
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import("@/views/Cart/Cart.vue"),
    meta: {
      requiresAuth: true,
      flags: [],
      layout: "home",
    },
  },
  {
    path: "/user-settings",
    name: "UserSettings",
    component: () => import("@/views/UserSettings/UserSettings.vue"),
    meta: {
      requiresAuth: true,
      flags: [],
      layout: "home",
    },
  },
  {
    path: "/contacts",
    name: "ContactsList",
    component: () => import("@/views/Contacts/ContactsList.vue"),
    meta: {
      requiresAuth: true,
      flags: [],
      layout: "home",
      title: "Контакты",
    },
  },
  {
    path: "/contacts/:id",
    name: "contacts-detail",
    component: ContactsDetail,
    meta: {
      requiresAuth: true,
      flags: [],
      layout: "home",
      title: "Просмотр контакта",
    },
  },
  {
    path: "/event-history",
    name: "event-history",
    component: () => import("@/views/EventHistory/EventHistoryTable.vue"),
    meta: {
      requiresAuth: true,
      flags: [],
      layout: "home",
      title: "Логирование",
    },
  },
  {
    path: "/financial-information",
    name: "financial-information",
    component: () =>
      import("@/views/FinancialInformation/FinancialInformation.vue"),
    meta: {
      requiresAuth: true,
      flags: [],
      layout: "home",
      title: "Финансовая информация",
    },
  },
  {
    path: "/order-analytics",
    name: "order-analytics",
    component: () => import("@/views/OrderAnalytics/OrderAnalytics.vue"),
    meta: {
      requiresAuth: true,
      flags: [],
      layout: "home",
      title: "Аналитика по заказам",
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("@/views/Notifications/Notifications.vue"),
    meta: {
      requiresAuth: true,
      flags: [],
      layout: "home",
      title: "Уведомления",
    },
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

function setDocumentTitle(route: any) {
  const appTitle = "MOK"

  if (route.meta.title) {
    document.title = route.meta.title
  } else {
    document.title = appTitle
  }
}

router.beforeEach((to, from, next) => {
    // after login to hide initial notifications on page change
  if (from.name === 'Home' && to.name !== 'Home') {
    Vue.prototype.$notification.destroy();
  }

  if (to.path.includes("recover")) {
    localStorage.removeItem("AUTH_TOKEN")
    localStorage.removeItem("REFRESH_TOKEN")
  }
  const isAuthorized = localStorageService.load("AUTH_TOKEN")

  setDocumentTitle(to)

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthorized) {
      next()
      return
    }
    next("/login")
  } else {
    if (isAuthorized) {
      next("/")
      return
    }
    next()
  }
})

export default router
