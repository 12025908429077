export default {
  errors: {},
  orderConfirm: false,
  recipeDetails: {
    le: {},
    re: {},
  },
  isShowStepTwo: false,
  lensFilters: null,
  lensList: null,
  lensFiltersChecked: {
    manufacture: [],
    index: [],
    design: [],
    property: [],
    tint: [],
    property_color: [],
  },
}
