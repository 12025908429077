export default {
  getGPKPagination: (_state: any) => _state.filters.GPK.pagination,
  getGPMPagination: (_state: any) => _state.filters.GPM.pagination,
  getGPKRange: (_state: any) => _state.filters.GPK.range,
  getGPMRange: (_state: any) => _state.filters.GPM.range,
  claimGPKFiltersChecked: (_state: any) =>
    _state.filters.GPK.claimFiltersChecked,
  claimGPMFiltersChecked: (_state: any) =>
    _state.filters.GPM.claimFiltersChecked,
  getGPKStatuses: (_state: any) => _state.filters.GPK.statuses,
  getGPMStatuses: (_state: any) => _state.filters.GPM.statuses,
  getOrderRxList: (_state: any) => _state.orderRxList,
  getFormGPM: (_state: any) => _state.formGPM,
  getGlobalValidation: (_state: any) => _state.globalValidation,
}
