import {apiClient} from "@/api/setupApi"

const BASE_VUE_APP_URL = process.env.VUE_APP_URL

// const BASE_API = "catalog/v1/catalog"

const NOMENCLATURE_GET_STIGMA_LENS = `v1/orders/stigma-specifications`
const NOMENCLATURE_GET_ASTIGMA_LENS = `v1/orders/astigma_list_specifications`
const NOMENCLATURE_GET_ASTIGMATIC_LENS = `v1/orders/nomenclature-specifications`

const NOMENCLATURE_EMPTY_GET_LENSES = `/v1/catalog/products_simplified`
const NOMENCLATURE_EXTENDED_GET_FILTERS = `/v1/catalog/filters?`
const NOMENCLATURE_EXTENDED_GET_LENSES = `api/v1/catalog/products?page=`
// const NOMENCLATURE_EXTENDED_GET_LENSES = `v1/catalog/products?page=`

const NOMENCLATURE_GET_BALANCE_BY_LENSES = `v1/orders/get_balance?`

const SEGMENTS = `${BASE_VUE_APP_URL}/api/v1/catalog/get_segments`
const CHANGE_SEGMENTS = `${BASE_VUE_APP_URL}/api/v1/segments/change_vision/`

const NOMENCLATURE_CHANGE_COUNT_LENSES = `${BASE_VUE_APP_URL}/api/api/v1/orders/warehouse/update/`
const NOMENCLATURE_GET_ORDER = `${BASE_VUE_APP_URL}/order/api/v1/orders/warehouse/load/`
const NOMENCLATURE_GET_ALL_ORDERS = `${BASE_VUE_APP_URL}/order/api/v1/orders/warehouse/total/`
const NOMENCLATURE_DELETE_ORDER = `${BASE_VUE_APP_URL}/order/api/v1/orders/warehouse/delete/`
const REPEAT_ORDER = `${BASE_VUE_APP_URL}/order/api/v1/orders/warehouse/duplicate/`

export default {
  getNomenclatureStigmaLens(data: { id: string, dia?: number, eye?: string, add?: string }) {
    return apiClient.get(`${NOMENCLATURE_GET_STIGMA_LENS}`, {
      params: {
        nomenclature_id: data.id,
        dia: data.dia || null,
        eye: data.eye || null,
        add: data.add || null
      }
    })
  },
  getNomenclatureAstigmaLens(data: object) {
    return apiClient.post(NOMENCLATURE_GET_ASTIGMA_LENS, {...data})
  },
  getNomenclatureAstigmaticLensRange(id: string) {
    return apiClient.get(`${NOMENCLATURE_GET_ASTIGMATIC_LENS}/range?nomenclature_id=${id}`)
  },
  getNomenclatureAstigmaticLens(data: { id: string, dia?: number, sphRange?: string, cyl_state?: boolean }) {
    return apiClient.get(`${NOMENCLATURE_GET_ASTIGMATIC_LENS}?nomenclature_id=${data.id}&dia=${data.dia || ""}&${data.cyl_state ? "sph" : "sph_minus"}=${data.sphRange}`)
  },
  getNomenclatureEmptyLenses(data: any) {
    return apiClient.post(
      `${NOMENCLATURE_EMPTY_GET_LENSES}?client_guid=${data.client_guid}&user_guid=${data.user_guid}`,
      {
        ordered_earlier_guids: data.ordered_earlier_guids,
      }
    )
  },
  getNomenclatureExtendedFilters(data: any = []) {
    return apiClient.get(
      `${NOMENCLATURE_EXTENDED_GET_FILTERS}manufacturer[]=${
        data.manufacturers || ""
      }&index[]=${data.indexes || ""}&design[]=${data.design || ""}&tint[]=${
        data.coating || ""
      }&sun_protect[]=${data.protection || ""}&segment[]=${data.segment || ""}`
    )
  },
  getNomenclatureExtendedLenses(data: any) {
    return apiClient.post(`${NOMENCLATURE_EXTENDED_GET_LENSES}${data.page}`, {
      ...data.filters,
    })
  },
  NomenclatureGetBalanceByLenses(data: any) {
    return apiClient.get(
      `${NOMENCLATURE_GET_BALANCE_BY_LENSES}spec_id=${data.id}&stash_guid=${data.stash_guid}&dp_id=${data.dp_id}`
    )
  },
  NomenclatureChangeCountLenses(data: any) {
    return apiClient.post(
      `${NOMENCLATURE_CHANGE_COUNT_LENSES}`,
      {...data},
      {baseURL: BASE_VUE_APP_URL}
    )
  },
  NomenclatureGetOrder(data: any) {
    return apiClient.post(
      `${NOMENCLATURE_GET_ORDER}`,
      {...data},
      {baseURL: BASE_VUE_APP_URL}
    )
  },
  NomenclatureGetAllOrders(data: any) {
    return apiClient.post(
      `${NOMENCLATURE_GET_ALL_ORDERS}`,
      {...data},
      {baseURL: BASE_VUE_APP_URL}
    )
  },
  getSegments(data?: any) {
    return apiClient.get(SEGMENTS, {params: data})
  },
  saveSegments(data?: any) {
    return apiClient.post(CHANGE_SEGMENTS, data, {baseURL: BASE_VUE_APP_URL})
  },
  deleteOrder(order: object) {
    return apiClient.post(NOMENCLATURE_DELETE_ORDER, order, {
      baseURL: BASE_VUE_APP_URL,
    })
  },
  repeatOrder(data: object) {
    return apiClient.post(REPEAT_ORDER, data, {baseURL: BASE_VUE_APP_URL})
  },
}
