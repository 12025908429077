import api from "@/api/eventHistory"

const BASE_URL = process.env.VUE_APP_API_URL || ''

export default {
  async getEventHistory({commit, state}: any) {
    const res = await api.getEventHistory(
      state.eventHistoryParams,
      state.pagination.current
    )
    commit("setEventHistory", res.results)
    commit("setCount", res.count)
    return res
  },

  async getEventHistoryFilters({commit}: any) {
    const res = await api.getEventHistoryFilters()
    commit("setEventFilters", res)
    return res
  },

  async getLinkToDownloadReport({commit, state}: any) {
    let {report_file} = await api.getLinkToDownloadReport(
      state.eventHistoryParams,
      state.pagination.current
    )

    const regex = /\/([^/]+)$/
    const match = report_file.match(regex)
    const fileName = match ? match[1] : null

    if (report_file[0] === '/' && BASE_URL[BASE_URL.length - 1] === '/') {
      report_file = report_file.substring(1)
    }

    const filePath = `${BASE_URL}${report_file}`

    return {
      fileName,
      filePath,
    }
  },
}
