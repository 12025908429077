<template lang="pug">
a-config-provider(:locale="locale")
  #app
    section(:is="layout")
      router-view
</template>

<script>
import Vue from "vue"
import AuthLayout from "@/components/AuthLayout.vue"
import HomeLayout from "@/components/HomeLayout.vue"
import ru_RU from "ant-design-vue/lib/locale-provider/ru_RU"

export default Vue.extend({
  name: "app",
  data() {
    return {
      locale: ru_RU,
    }
  },
  components: {
    AuthLayout,
    HomeLayout,
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || "auth"}-layout`
    },
  },
})
</script>
