import values from "./data/data"

export default {
  getTabsColoringLencor: (_state: any) => {
    return values.tabsColoringLencor
  },
  getTabsColoringBBGR: (_state: any) => {
    return values.tabsColoringBBGR
  },
  getColoringLencor: (_state: any) => {
    return values.coloringLencor
  },
  getColoringBBGR16: (_state: any) => {
    const res = JSON.parse(JSON.stringify(values.coloringBBGR))
    res['Сплошное'].color = res['Сплошное'].color.filter(({ title }: { title: string }) => title === 'Коричневый' || title === 'Черный' || title === 'Серо-зеленый' || title === 'Серый' || title === 'Желтый')
    const array = ["Короткий Градиент", "Стандартный Градиент", "Длинный Градиент"]
    array.forEach(item =>
      res[item].color = res[item].color.filter(({ title }: { title: string }) => title === 'Коричневый' || title === 'Черный' || title === 'Серо-зеленый' || title === 'Серый')
    )
    return res
  },
  getColoringBBGR15: (_state: any) => {
    return values.coloringBBGR
  },
  getColoringBBGR167: (_state: any) => {
    const res = JSON.parse(JSON.stringify(values.coloringBBGR))
    res['Сплошное'].color = res['Сплошное'].color.filter(({ title }: { title: string }) => title === 'Коричневый' || title === 'Черный' || title === 'Серо-зеленый' || title === 'Серый' || title === 'Желтый' || title === 'Синий' || title === 'Бордовый' || title === 'Парма')
    res['Стандартный Градиент'].color = res['Стандартный Градиент'].color.filter(({ title }: { title: string }) => title === 'Коричневый' || title === 'Черный' || title === 'Серо-зеленый' || title === 'Серый' || title === 'Синий' || title === 'Бордовый' || title === 'Парма')
    return res
  },
  getTabsColorXperio: (_state: any) => {
    return values.tabsColorXperio
  },
  getTabsColorTransitions: (_state: any) => {
    return values.tabsColorTransitions
  },
  getColorXperio: (_state: any) => {
    return values.colorXperio
  },
  getColorTransitions: (_state: any) => {
    return values.colorTransitions
  },
  getColorTransitionsXTRActive: (_state: any) => {
    return values.colorTransitionsXTRActive
  },
  getColorSunsensy: (_state: any) => {
    return values.colorSunsensy
  },
  getTotalCount: (_state: any) => {
    return _state.totalCount
  },
  getVisibleFrameState: (_state: any) => {
    return Object.keys(_state.orderRXRules).length ? _state.orderRXRules.frame !== 0 : false
  },
}
