export default {
  orders: [],
  deliveryAddresses: [],
  deliveryCompanies: [],
  currentOrder: null,
  itemsCount: null,
  itemsWarehouseCount: null,
  itemsRXCount: null,
  frameColoring: {},
  createGlassesDetails: {},
  currentTab: 'warehouse'
}
