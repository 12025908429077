// @ts-nocheck

import api from "@/api/historyOrders"

export default {
  async getHistoryOrders({commit}: any, data: any) {
    try {
      const res: any = await api.getHistoryOrders(data)
      return res || null
    } catch (error) {
      return {error: error.response.data}
    }
  },
}
