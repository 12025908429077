export default {
  collections: [],
  deliveryPoints: [],
  ownCurrentPath: "",
  notificationsColumns: [
    {
      title: "Номер",
      dataIndex: "idx",
      key: "idx",
      width: 100,
    },
    {
      title: "Текст уведомления",
      dataIndex: "text",
      key: "text",
    },
    {
      title: "Ссылка",
      dataIndex: "url",
      key: "url",
    },
    {
      title: " ",
      dataIndex: "operation",
      key: "operation",
      scopedSlots: {customRender: "operation"},
      width: 100,
    },
  ],
  notifications: [],
  notificationsMeta: null,
}
