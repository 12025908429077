<template lang="pug">
  div 
    h1 Пользовательское соглашение
    p Текст пользовательского соглашения
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  name: "agreement",
})
</script>

<style></style>
