import api from "@/api/meta"
import general from "@/api/general"

export default {
  async getCollections({commit}: any, data: object) {
    try {
      const res = await api.getCollections(data)
      commit("setValue", {collections: res})
      return res
    } catch (error) {
      // console.log(error);
      return error
    }
  },

  async getDeliveryPoints({commit}: any, data: object) {
    try {
      const res = await general.getDeliveryPoints(data)
      commit("setDeliveryPoints", res.data)
      return res
    } catch (error) {
      console.error("CATALOG GET DELIVERY POINTS", error)
      return error
    }
  },
  // Notifications
  async getNotifications({commit}: any) {
    try {
      const res = await general.getNotifications()
      commit("setNotifications", res)
      return res
    } catch (error) {
      return error
    }
  },
  async getNotificationsMeta({commit}: any) {
    try {
      const res = await general.getNotificationsMeta()
      commit("setNotificationsMeta", res)
      return res
    } catch (error) {
      return error
    }
  },
  async getNotificationsByUser() {
    try {
      return await general.getNotificationsByUser()
    } catch (error) {
      return error
    }
  },
  async createNotifications({commit}: any, data: any) {
    try {
      return await general.createNotification(data)
    } catch (error: any) {
      return { error: error.response.data }
    }
  },
  async readNotification({commit}: any, id: string) {
    try {
      return await general.readNotification(id)
    } catch (error) {
      return error
    }
  },
  async deleteNotification({commit}: any, id: string) {
    try {
      const res: any = await general.deleteNotification(id)
      commit("deleteNotifications", id)
      return res
    } catch (error) {
      return error
    }
  },
}
