export default {
  user: {},
  users: {
    data: [],
    meta: {
      total: 7,
      page: 1,
      records_on_page: 10,
    },
  },
  roles: {
    data: [],
    meta: {
      total: 7,
      page: 1,
      records_on_page: 10,
    },
  },
  departments: {
    subdivisions: [],
    meta: {
      total: 7,
      page: 1,
      records_on_page: 10,
    },
  },
}
