import {apiClient} from "@/api/setupApi"

const GET_DELIVERY_POINTS = `api/v1/orders/delivery_points/`

const GET_NOTIFICATIONS = `v1/notifications/manage`
const GET_NOTIFICATIONS_META = `v1/notifications/meta`
const GET_NOTIFICATION_BY_USER = `v1/notifications`
const CREATE_NOTIFICATION = `v1/notifications/create`
const READ_NOTIFICATIONS = `v1/notifications/read`
const DELETE_NOTIFICATION = `v1/notifications/`

export default {
  getDeliveryPoints(params: any) {
    return apiClient.get(GET_DELIVERY_POINTS, {params})
  },

  getNotifications() {
    return apiClient.get(GET_NOTIFICATIONS)
  },
  getNotificationsByUser() {
    return apiClient.get(`${GET_NOTIFICATION_BY_USER}`)
  },
  getNotificationsMeta() {
    return apiClient.get(GET_NOTIFICATIONS_META)
  },
  createNotification(params: any) {
    return apiClient.post(CREATE_NOTIFICATION, {...params})
  },
  readNotification(params: any) {
    return apiClient.post(READ_NOTIFICATIONS, {...params})
  },
  deleteNotification(id: string) {
    return apiClient.delete(`${DELETE_NOTIFICATION}${id}`)
  },
}
