// import client from '@/api/client';
import { apiClient } from "@/api/setupApi";

const BASE_API = "api/v1"

const token = process.env.VUE_APP_DADATA_API_KEY

const CLIENTS_ADD_CLIENTS_XLSX = `${BASE_API}/clients/add_clients/`
const CLIENTS_GET_CLIENT = `${BASE_API}/clients/full_view/`
const CLIENTS_GET_CLIENTS = `${BASE_API}/clients/`
const CLIENTS_CREATE_CLIENT = `${BASE_API}/clients/create_client/`
const CLIENTS_UPDATE_CLIENT = `${BASE_API}/clients/update_client/`
const CLIENTS_CHANGE_ROLE = `${BASE_API}/users/change_role/`

const GET_AVAILABLE_CLIENTS = `api/v1/clients/short`
const CLIENTS_GET_EMPLOYEES = `${BASE_API}/clients/employers/`

const GET_DELIVERY_POINTS = `${BASE_API}/orders/delivery_points/`
const CREATE_DELIVERY_POINTS = `${BASE_API}/orders/delivery_points/create/`
const UPDATE_DELIVERY_POINTS = `${BASE_API}/orders/delivery_points/update/`

const EMPLOYEES_ADD_NEW_EMPLOYEE = `${BASE_API}/users/create_user/`
const EMPLOYEES_UPDATE_EMPLOYEE = `${BASE_API}/users/update_user/`
const CLIENTS_GET_LEADERS = `${BASE_API}/clients/get_users/?client=`

const CLIENTS_ADDRESSES = (id: string) => `v1/orders/delivery-points/${id}/addresses/`

export default {
  addClientsXLSX(data: any) {
    return apiClient.post(CLIENTS_ADD_CLIENTS_XLSX, data)
  },
  getClient(data: object) {
    return apiClient.get(CLIENTS_GET_CLIENT, {params: data})
  },
  getClientsList(data: object) {
    return apiClient.get(CLIENTS_GET_CLIENTS, {params: data})
  },
  getAvailableClients(data: object): Promise<any> {
    return apiClient.get(GET_AVAILABLE_CLIENTS)
  },
  createClient(data: object) {
    return apiClient.post(CLIENTS_CREATE_CLIENT, data)
  },
  updateClient(data: object) {
    return apiClient.post(CLIENTS_UPDATE_CLIENT, data)
  },
  changeRole(data: object) {
    return apiClient.post(CLIENTS_CHANGE_ROLE, data)
  },
  getClientEmployeesList(params: object) {
    return apiClient.get(CLIENTS_GET_EMPLOYEES, {params})
  },
  postClientsEmployee(data: object) {
    return apiClient.post(EMPLOYEES_ADD_NEW_EMPLOYEE, data)
  },
  patchClientsEmployee(data: object) {
    return apiClient.post(EMPLOYEES_UPDATE_EMPLOYEE, data)
  },

  getDeliveryPointsList(params: any) {
    return apiClient.get(GET_DELIVERY_POINTS, {params})
  },
  getDeliveryPointsListShort() {
    return apiClient.get('v1/orders/delivery-points/short')
  },
  postDeliveryPoint(data: object) {
    return apiClient.post(CREATE_DELIVERY_POINTS, data)
  },
  patchDeliveryPoint(data: object) {
    return apiClient.post(UPDATE_DELIVERY_POINTS, data)
  },
  getLeaders(data: any) {
    return apiClient.get(`${CLIENTS_GET_LEADERS}${data}`)
  },

  createAddress(id: string, data: any) {
    return apiClient.post(CLIENTS_ADDRESSES(id), data)
  },
  updateAddress(idPoint: string, idAddress: string, data: any) {
    return apiClient.patch(`${CLIENTS_ADDRESSES(idPoint)}${idAddress}`, data)
  },
  removeAddress(idPoint: string, idAddress: string) {
    return apiClient.delete(`${CLIENTS_ADDRESSES(idPoint)}${idAddress}`)
  },
  getPointsAddresses(id: string) {
    return apiClient.get(CLIENTS_ADDRESSES(id))
  },

  async getDadataAddresses(query: string) {
    const fetchedData = await fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Token ${token}`
      },
      body: JSON.stringify({
        query,
        count: 10,
        to_bound: {},
        from_bound: {},
        language: 'ru',
        locations: [],
        locations_boost: []
      })
    })
    const body = await fetchedData.json()
    return body.suggestions.map((item: any) => ({
      label: item.value,
      value: JSON.stringify(item),
      key: item.value,
    }))
  },
}
