export default {
  eventHistory: [],
  eventHistoryFilters: {
    created_at: [],
    authors: [],
    delivery_points: [],
    triggers: [],
  },
  eventHistoryParams: {
    created_at_before: "",
    created_at_after: "",
    authors: [],
    delivery_points: [],
    triggers: [],
    ordering: "",
  },
  pagination: {
    total: null,
    current: 1,
    pageSize: 10,
  },
  range: null,
}
