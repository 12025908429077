<template lang="pug">
  .ContactsDetail.Content(v-if="loaded")
    PageHeader(
      :title="`${contact.user_data.lastname} ${contact.user_data.name}`"
      :hasBackButton="true"
      @click="routeToList"
    )

    .ContentInner
      //- pre {{ contact }}
      //- pre {{ collections.subdivision }}

      a-form-item
        img.ContactsDetail_Avatar(:src="avatarUrl")

      a-row(
        :gutter='24',
        justify="space-between",
        align="middle"
      )
        a-col(:sm='12')
          a-form-item(label='ФИО')
            | {{ `${contact.user_data.lastname} ${contact.user_data.name} ${contact.user_data.middlename}` }}
          //- a-form-item(label='Имя')
          //-   | {{ contact.user_data.name }}
          //- a-form-item(
          //-   label='Отчество'
          //-   v-if="contact.user_data.middlename"
          //- )
          //-   | {{ contact.user_data.middlename }}
          a-form-item(label='E-mail')
            a(:href="`mailto:${contact.user_data.email}`") {{ contact.user_data.email }}
          a-form-item(
            label='Телефон'
            v-if="contact.user_data.phone"
          )
            a(:href="`tel:${contact.user_data.phone}`") {{ contact.user_data.phone }}
        a-col(:sm='12')
          a-form-item(label='Роль')
            | {{ contact.role_name }}
          a-form-item(label='Статус')
            a-badge(
              :status="contact.user_dataюis_active ? 'success' : 'default'"
            )
            | {{ contact.user_dataюis_active ? 'Активный' : 'Неактивный' }}
          a-form-item(
            label='Подразделение'
            v-if="contact.user_data.subdivision"
          )
            | {{ contact.user_data.subdivision }}
          a-form-item(
            label='Должность'
            v-if="contact.user_data.position"
          )
            | {{ contact.user_data.position }}
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex"
import PageHeader from "@/components/ui/PageHeader.vue"
import {getAvatarLink} from "@/utils/url"

export default {
  name: "ContactsDetail",

  components: {
    PageHeader,
  },

  async mounted() {
    await this.getUser(this.$route.params.id)
    this.loaded = true
  },

  data() {
    return {
      loaded: false,
    }
  },

  computed: {
    ...mapState({collections: "collections"}),
    ...mapGetters("employees", {
      contact: "getUser",
    }),
    avatarUrl() {
      return getAvatarLink(this.contact.user_data.userpic_url) || ""
    },
    // subdivision() {
    //   const val = this.collections.subdivision.find(el => el.value === this.contact.user_data.subdivision);
    //   return val ? val.value : [];
    // },
  },

  methods: {
    ...mapActions("employees", ["getUser"]),
    routeToList() {
      this.$router.push({name: "ContactsList"})
    },
  },
}
</script>

<style lang="scss" scoped>
.ContactsDetail_Avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.avatar {
  object-fit: cover;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
</style>
