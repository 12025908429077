import {
  ShipmentInformation,
  PaymentInformation,
  DiscountInformation,
} from "@/store/modules/financialInforamation/types"

export default {
  shipmentPaymentInformation: {
    tableList: [] as ShipmentInformation[],
    ordering: "",
    pagination: {
      total: null,
      current: 1,
      pageSize: 10,
    },
  },

  paymentScheduleInformation: {
    tableList: [] as PaymentInformation[],
    ordering: "payment_date",
    pagination: {
      total: null,
      current: 1,
      pageSize: 10,
    },
  },

  discountInformation: {
    tableList: [] as DiscountInformation[],
    ordering: "",
    pagination: {
      total: null,
      current: 1,
      pageSize: 10,
    },
  },
}
