import jwt_decode from "jwt-decode"

export type JWT = {
  token_type: string
  exp: number
  user_id: string
  groups: string[]
  permissions: string[]
  client_guid: string
  is_mok_emp: boolean | null
}

export const parseJWT = (jwt: string) => {
  const jwtDecode: JWT = jwt_decode(jwt)

  const permissionsObj = () =>
    jwtDecode.permissions.reduce((target: any, key: any) => {
      target[key] = true
      return target
    }, {})

  return {
    getPermissions: permissionsObj(),
    getRole: jwtDecode.groups[0],
    getExp: jwtDecode.exp,
    getClientId: jwtDecode.client_guid,
    getIsMokEmp: jwtDecode.is_mok_emp,
  }
}
