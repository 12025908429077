import {localStorageService} from "@/services/localStorage"
import {parseJWT} from "@/utils/parseJWT"
import axios from "axios"

const BASE_URL = process.env.VUE_APP_API_URL

const createAxios = () => {
  const client = axios.create({
    timeout: 30000,
    headers: {
      "Content-Type": "application/json",
    },
    maxContentLength: 10000,
  })

  client.defaults.baseURL = BASE_URL
  client.defaults.withCredentials = false

  client.interceptors.request.use(
    (config) => {
      const token = localStorageService.load("AUTH_TOKEN")
      if (!!token) {
        config.headers["Authorization"] = `Bearer ${token}`
      } else {
        delete config.headers.Authorization
      }

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )

  client.interceptors.response.use(
    (res) => {
      return res.data
    },
    async (err) => {
      const originalConfig = err.config
      if (originalConfig.url !== "/login" && err.response) {
        // Access Token was expired
        if (err.response.status === 403 && !originalConfig._retry) {
          const token = localStorageService.load("AUTH_TOKEN")
          const refreshToken = localStorageService.load("REFRESH_TOKEN")

          const expireTime = parseJWT(token || "{}").getExp

          if (expireTime * 1000 < new Date().getTime()) {
            originalConfig._retry = true
            try {
              const resNewToken = await axios.post(
                "/api/api/v1/token/refresh/",
                {
                  refresh: refreshToken,
                }
              )

              const {data} = resNewToken
              localStorageService.save({key: "AUTH_TOKEN", value: data.access})

              return client(originalConfig)
            } catch (_error) {
              if (document.location.pathname !== "/login") {
                localStorageService.remove("AUTH_TOKEN")
                localStorageService.remove("REFRESH_TOKEN")
                localStorageService.remove('clientsPointFilter')
                document.location.href = "/login"
                return Promise.reject(_error)
              }
            }
          }
        }
      }
      return Promise.reject(err)
    }
  )

  return client
}

export const apiClient = createAxios()
