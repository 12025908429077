// @ts-nocheck
import api from "@/api/cart"

export default {
  async getDeliveryAddresses({commit}: any) {
    try {
      const res: any = await api.getDeliveryAddresses()
      commit("setDeliveryAddresses", res)
    } catch (error) {
      // console.log(error);
    }
  },
  async getDeliveryCompanies({commit}: any) {
    try {
      const res: any = await api.getDeliveryCompanies()
      commit("setDeliveryCompanies", res)
    } catch (error) {
      // console.log(error);
    }
  },
  async updateOrder({dispatch}: any, data: object) {
    try {
      return await api.updateOrder(data)
    } catch (error) {
      return {error: error.response.data}
    }
  },
  async submitOrder({dispatch}: any, data: object) {
    try {
      return await api.submitOrder(data)
    } catch (error) {
      return {error: error.response.data}
    }
  },
  async submitOrderRx({dispatch}: any, id: string) {
    try {
      return await api.submitOrderRx(id)
    } catch (error) {
      return {error: error.response.data}
    }
  },
  async approveOrder({dispatch}: any, data: object) {
    try {
      return await api.approveOrder(data)
    } catch (error) {
      return {error: error.response.data}
    }
  },
  async approveOrderRX({dispatch}: any, id: string) {
    try {
      return await api.approveOrderRX(id)
    } catch (error) {
      return {error: error.response.data}
    }
  },
  async getOrders({commit}: any, rx: boolean) {
    try {
      const { results } = await api.getOrders(rx)
      const count = await api.cartItemsCart()
      commit("setOrders", results)
      commit("setItemsCountInCart", count)
      return results
    } catch (error) {
      return {error: error.response.data}
    }
  },
  async getNomenclatureEmptyGetOldOrdered({commit}: any, data: object) {
    try {
      const res = await api.getNomenclatureEmptyGetOldOrdered(data)
      return res.data
    } catch (error) {
      return error
    }
  },
  async createGlasses({ commit }: any, { id, data }: object) {
    try {
      return await api.createGlasses(id, data)
    } catch (error) {
      return error
    }
  },
  async deleteWorkshop({ commit }: any, id: string) {
    try {
      return await api.deleteWorkshop(id)
    } catch (e) {
      return e
    }
  },
  async deleteRXOrder ({ commit }: any, id: string) {
    try {
      return await api.deleteRXOrder(id)
    } catch (e) {
      return { error: e }
    }
  }
}
