// import  from '@/api/apiClient';
import {apiClient} from "@/api/setupApi"

const BASE_API = "api/v1"

const EMPLOYEES_ADD_USERS_XLSX = `${BASE_API}/users/add_users/`
const EMPLOYEES_GET_USERS = `${BASE_API}/users/`
const EMPLOYEES_ADD_NEW_EMPLOYEE = `${BASE_API}/users/create_user/`
const EMPLOYEES_UPDATE_EMPLOYEE = `${BASE_API}/users/update_user/`
const EMPLOYEES_UPDATE_USER = `${BASE_API}/users/`

const GET_ROLES = `${BASE_API}/roles/`
const GET_USER_SIMPLEFIED = `/api/v1/users_simplefied/`
const CHANGE_ROLE = `${BASE_API}/users/change_role/`
const CREATE_ROLE = `${BASE_API}/roles/create_role/`
// const UPDATE_ROLE = `${BASE_API}/roles/update_role/`
const UPDATE_ROLE = `${BASE_API}/roles/`

const GET_META_SUBDIVISIONS = `${BASE_API}/subdivisions/`
const SAVE_SUBDIVISIONS = `${BASE_API}/subdivisions/`
const UPDATE_SUBDIVISIONS = `${BASE_API}/subdivisions/update_subdivision/`

const SEND_INVITATION = `${BASE_API}/authorization/send_email_to_activate/`

export default {
  addUsersXLSX(data: any) {
    return apiClient.post(EMPLOYEES_ADD_USERS_XLSX, data)
  },
  getUsersList(params?: any): Promise<any> {
    return apiClient.get(EMPLOYEES_GET_USERS, {params})
  },
  getUser(id?: any): Promise<any> {
    return apiClient.get(`${EMPLOYEES_GET_USERS}${id}/`) // slash in the end is feature
  },
  addNewEmployee(data: any) {
    return apiClient.post(EMPLOYEES_ADD_NEW_EMPLOYEE, data)
  },
  updateEmployee(data: any) {
    return apiClient.post(EMPLOYEES_UPDATE_EMPLOYEE, data)
  },
  updateUser(data: any) {
    return apiClient.patch(`${EMPLOYEES_UPDATE_USER}${data.id}/`, data)
  },

  getRoles(params?: any) {
    return apiClient.get(GET_ROLES, {params})
  },
  postRole(data: any) {
    return apiClient.post(CREATE_ROLE, data)
  },
  patchRole(data: any) {
    return apiClient.put(`${UPDATE_ROLE}${data.id}/`, data)
  },
  changeRole(data: object) {
    return apiClient.post(CHANGE_ROLE, data)
  },

  getDepartmentsList(params?: any) {
    return apiClient.get(GET_META_SUBDIVISIONS, {params})
  },
  postDepartment(dept: {name: string; leader: string}) {
    return apiClient.post(SAVE_SUBDIVISIONS, dept)
  },
  patchDepartment(dept: {name: string; leader: string; id?: number | string}) {
    return apiClient.post(UPDATE_SUBDIVISIONS, dept)
  },

  sendInvitation(list: any[]) {
    return apiClient.post(SEND_INVITATION, list)
  },
  getUsersSimplified() {
    return apiClient.get(GET_USER_SIMPLEFIED)
  },
}
