export default {
  promotions: [],
  promotion: {},
  promotionTerms: {},
  pagination: {
    currentPage: 1,
    count: 0,
    pageSize: 10,
  },
  promoCodes: [],
  selectedPromoCodes: [],
  // errors: [],
}
