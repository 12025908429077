import api from "@/api/reclamation"
import {ReferenceNumber} from "@/store/modules/reclamation/types"

interface Params {
  [key: string]: string
}

export default {
  async postReclamation({commit}: any, data: object) {
    const result = await api.postReclamation(data)
    return result
  },

  async postReferenceNumber({commit}: any, data: ReferenceNumber) {
    const result = await api.postReferenceNumber(data)
    return result
  },

  async getReclamationList({commit, state}: any) {
    const {claims_status, ...rest} = state.filters.GPK.claimFiltersChecked

    const params: Params = {
      page: state.filters.GPK.pagination.current,
      ...(claims_status.length ? {claims_status: claims_status.join(",")} : {}),
    }

    for (const key in rest) {
      if (rest[key]) {
        params[key] = rest[key]
      }
    }

    const result = await api.getReclamationList(params)
    commit("setReclamationList", result.results)
    commit("setStatuses", {
      type: "GPK",
      statuses: result.filter_data.claims_status,
    })
    commit("setNext", {type: "GPK", link: result.next})
    return result
  },

  async getReclamationGPMList({commit, state}: any) {
    const {claims_status, ...rest} = state.filters.GPM.claimFiltersChecked

    const params: Params = {
      page: state.filters.GPM.pagination.current,
      ...(claims_status.length ? {claims_status: claims_status.join(",")} : {}),
    }

    for (const key in rest) {
      if (rest[key]) {
        params[key] = rest[key]
      }
    }

    const result = await api.getReclamationGPMList(params)
    commit("setReclamationGPMList", result.results)
    commit("setStatuses", {
      type: "GPM",
      statuses: result.filter_data.claims_status,
    })
    commit("setNext", {type: "GPM", link: result.next})
    return result
  },

  async editGPMReclamation({commit}: any, data: any) {
    const result = await api.editGPMReclamation(data)
    return result
  },

  async getOrdersRxShort({commit}: any) {
    const result = await api.getOrdersRxShort()
    commit("setOrdersRxShort", result)
    return result
  },

  async postGPMReclamation({commit}: any, data: object) {
    const result = await api.postGPMReclamation(data)
    return result
  },
}
