import Vue from "vue"
import VueDadata from "vue-dadata"
import VueMoment from "vue-moment"
import moment from "moment"
// import client from "@/api/client";
// import init from "@/helpers/interceptors";
import router from "./router"
import store from "./store"

import App from "./App.vue"
import "@/plugins/ant-design-vue"
import "./assets/styles/global.less"

require("moment/locale/ru")

Vue.config.productionTip = false
// TODO: удалить этот дев тул чтобы не было его в проде
Vue.config.devtools = true

Vue.use(VueDadata)
Vue.use(VueMoment, {
  moment,
})

// init(store, client);

new Vue({
  name: "Root",
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")

// TODO: uncomment when Sentry ready
// const { VUE_APP_SENTRY_DSN = '' } = process.env;
// try {
//   Sentry.init({
//     dsn: VUE_APP_SENTRY_DSN,
//     integrations: [new Integrations.Vue({
//       Vue,
//       attachProps: true,
//       logErrors: true,
//     })],
//   })
// } catch (error) {
//   console.error('Failed to initialize sentry: ', error)
// }
