export default {
  filters: {
    GPK: {
      statuses: null,
      claimFiltersChecked: {
        claims_number: null,
        claims_status: [],
        inner_order_number: null,
        created_after: null,
        created_before: null,
      },
      pagination: {
        current: 1,
        next: null,
      },
      range: null,
    },
    GPM: {
      statuses: null,
      claimFiltersChecked: {
        claims_number: null,
        claims_status: [],
        client: null,
        created_after: null,
        created_before: null,
      },
      pagination: {
        current: 1,
        next: null,
      },
      range: null,
    },
  },

  // GPK
  errors: {},
  orderConfirm: false,
  reclamationList: [],

  // GPM
  reclamationGPMList: [],
  orderRxList: [],
  formGPMErrors: {},

  formGPM: {
    contactInfo: {
      contact_person: null,
      phone_number: null,
      contact_person_doctor: null,
      phone_number_doctor: null,
      contact_person_optical: null,
      phone_number_optical: null,
    },
    replaceableLenses: {
      order_id: null,
      nomenclature_guid: null,
      receipt_glasses_date: null,
      appeals_no_adaptation_date: null,
      frame_type: null,
      frame_type_value: null,
      refraction_order_lens_data: {
        re: {
          sph: null,
          cyl: null,
          axis: null,
          add: null,
          base1: null,
          prism1: null,
          base2: null,
          prism2: null,
          vis: null,
          dia: null,
        },
        le: {
          sph: null,
          cyl: null,
          axis: null,
          add: null,
          base1: null,
          prism1: null,
          base2: null,
          prism2: null,
          vis: null,
          dia: null,
        },
      },
      frame_params: {
        frame_parameters_middle: {
          A: null,
          B: null,
          DBL: null,
          CVD: null,
          PTILT: null,
          FFA: null,
        },
        frame_parameters_rows_re: {
          PD: null,
          H: null,
        },
        frame_parameters_rows_le: {
          PD: null,
          H: null,
        },
      },
    },
    conclusion: {
      adapting_fail_reason: null,
    },
    previousGlasses: {
      glasses_type: null,
      glasses_type_name: null,
      lens_name: null,
      binocular_balance_far: null,
      binocular_balance_near: null,
      leading_eye: null,
      pd_prev_glasses: null,
      pd: null,
      harmon: null,
      revip: null,
      refraction_glass_data: {
        re: {
          sph: null,
          cyl: null,
          axis: null,
          add: null,
          base1: null,
          prism1: null,
          base2: null,
          prism2: null,
          vis: null,
        },
        le: {
          sph: null,
          cyl: null,
          axis: null,
          add: null,
          base1: null,
          prism1: null,
          base2: null,
          prism2: null,
          vis: null,
        },
      },
      refraction_lens_data: {
        re: {
          sph: null,
          cyl: null,
          axis: null,
        },
        le: {
          sph: null,
          cyl: null,
          axis: null,
        },
      },
    },
    clientData: {
      customer_name: null,
      customer_profession: null,
      customer_birth_date: null,
      customer_driver_license: null,
      customer_pc_usage: null,
      customer_init_reason: null,
    },
    lensComplaintAnalysis: {
      far_area: {
        visus_low: {title: "Visus снижен", isChecked: false, value: null},
        visus_improves_with: {
          title: "Visus улучшается при",
          isChecked: false,
          value: null,
        },
        visus_low_periphery: {
          title:
            "Visus снижен по периферии - «сужение поля зрения», «мутно видно по бокам",
          isChecked: false,
        },
        floating_movement_effect: {
          title: "Плавающий эффект при движении",
          isChecked: false,
        },
        other: {title: "Другое", isChecked: false, value: null},
      },
      medium_area: {
        visus_low: {title: "Visus снижен", isChecked: false, value: null},
        visus_improves_with: {
          title: "Visus улучшается при",
          isChecked: false,
          value: null,
        },
      },
      near_area: {
        visus_low: {title: "Visus снижен", isChecked: false, value: null},
        visus_improves_with: {
          title: "Visus улучшается при",
          isChecked: false,
          value: null,
        },
        close_range_too_narrow: {
          title: "Зона для близи слишком узкая",
          isChecked: false,
        },
        other: {title: "Другое", isChecked: false, value: null},
      },
    },
  },
}
