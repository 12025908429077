import {apiClient} from "@/api/setupApi"

const BASE_API = "v1"
const GET_EVENT_HISTORY = `${BASE_API}/logger`
const GET_EVENT_HISTORY_FILTERS = `${BASE_API}/logger/filter`
const GET_LINK_TO_DOWNLOAD_REPORT = `${BASE_API}/logger/report`

function buildParams(filters: any, page: number): any {
  const {
    ordering,
    authors,
    delivery_points,
    triggers,
    created_at_before,
    created_at_after,
  } = filters

  const params: any = {
    page,
    authors: authors?.join(",") || [],
    delivery_points: delivery_points?.join(",") || [],
    triggers: triggers?.join(",") || [],
  }
  if (created_at_before) params.created_at_before = created_at_before
  if (created_at_after) params.created_at_after = created_at_after
  if (ordering) params.ordering = ordering

  return params
}

export default {
  getEventHistory(filters: any, page = 1): Promise<any> {
    const params = buildParams(filters, page)
    return apiClient.get(GET_EVENT_HISTORY, {params})
  },

  getLinkToDownloadReport(filters: any, page = 1): Promise<any> {
    const params = buildParams(filters, page)
    return apiClient.get(GET_LINK_TO_DOWNLOAD_REPORT, {params})
  },
  getEventHistoryFilters() {
    return apiClient.get(GET_EVENT_HISTORY_FILTERS)
  },
}
