// @ts-nocheck

import api from "@/api/employees"
import authApi from "@/api/auth"

export default {
  async addUsersXLSX({commit}: any, data: object) {
    try {
      await api.addUsersXLSX(data)
    } catch (error) {
      // console.log("ошибка в загрузке файла XLSX");
    }
  },
  async addNewEmployee({commit}: any, data: object) {
    try {
      const res = await api.addNewEmployee(data)
      return res
    } catch (error) {
      return {error: error.response.data}
    }
  },
  async updateEmployee({commit, rootState}: any, data: object) {
    try {
      const res = await api.updateEmployee(data)
      await commit("auth/completeUser", res, {root: true})
      return res
    } catch (error) {
      return {error: error.response.data}
    }
  },

  async getUsers({commit}: any, params: object) {
    try {
      const res = await api.getUsersList(params)
      const users = res.data.map((userInfo: any) => {
        const {role_name, role_data, ...userData} = userInfo
        return {
          role_name: userInfo.role_name,
          role_data: userInfo.role_data,
          user_data: {
            ...userData,
            subdivision: userData?.subdivision?.id,
            subdivision_name: userData?.subdivision?.subdivision,
          },
        }
      })

      commit("setValue", {users: {data: users, meta: res.meta}})
    } catch (error) {
      // console.log(error);
    }
  },
  async getUser({commit}: any, id: string) {
    try {
      const res = await api.getUser(id)

      const {role_name, role_data, ...userData} = res

      const user = {
        role_name: res.role_name,
        role_data: res.role_data,
        user_data: {
          ...userData,
        },
      }

      commit("setValue", {user: user})
    } catch (error) {
      // console.log(error);
    }
  },
  async getRoles({commit}: any, params: object) {
    try {
      const res = await api.getRoles(params)
      // commit("setValue", {roles: (res as any).results})
      commit("setValue", {roles: res})
    } catch (error) {
      // console.log(error);
    }
  },
  async saveRole({dispatch}: any, roleData: any) {
    try {
      if (roleData.id) {
        await api.patchRole(roleData)
      } else {
        await api.postRole(roleData)
      }
      // dispatch('getRoles');
    } catch (error) {
      // console.log(error);
    }
  },
  async changeRole({commit}: any, {users, role}: any) {
    try {
      await api.changeRole({users, role})
    } catch (error) {
      // console.log(error);
    }
  },
  async sendInvitation({commit}: any, users: any[]) {
    try {
      return api.sendInvitation({users} as any)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getDepartments({commit}: any, data: object) {
    try {
      const res = await api.getDepartmentsList(data)
      commit("setValue", {departments: res})
    } catch (error) {
      // console.log(error);
    }
  },
  async saveDepartment(
    context: any,
    department: {
      name: string
      leader: string
      id?: number | string
      newItem: boolean
    }
  ) {
    try {
      if (!department.newItem) {
        await api.patchDepartment(department)
      } else {
        await api.postDepartment(department)
      }
    } catch (error) {
      // console.log(error);
    }
  },
  async getUsersSimplified() {
    try {
      return await api.getUsersSimplified().then((res: any) => res.data)
    } catch (error) {
      return error
    }
  },
}
