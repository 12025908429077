import {
  LensFilters,
  RecipeDetails,
  FiltersChecked,
} from "@/store/modules/lensPicker/types"

export default {
  setErrors(_state: any, data: any) {
    if (Object.keys(data).length) {
      _state.errors[data.key] = data.value
    } else {
      _state.errors = {}
    }
  },

  setOrderConfirm(_state: any, confirm: boolean) {
    _state.orderConfirm = confirm
  },

  setShowStepTwo(_state: any, value: boolean) {
    _state.isShowStepTwo = value
  },

  setRecipeDetails(_state: any, details: RecipeDetails) {
    _state.recipeDetails.le = details.le
    _state.recipeDetails.re = details.re
  },

  clearRecipeDetails(_state: any) {
    _state.recipeDetails.le = {}
    _state.recipeDetails.re = {}
  },

  clearFilters(_state: any) {
    const filters = _state.lensFiltersChecked
    Object.entries(filters).forEach(([key]) => {
      filters[key] = []
    })
  },

  setLensFilters(_state: any, data: LensFilters) {
    _state.lensFilters = data
  },

  setLensList(_state: any, data: any) {
    _state.lensList = data
  },

  setLensFiltersChecked(_state: any, data: FiltersChecked) {
    _state.lensFiltersChecked[data.label] = data.value
  },
}
