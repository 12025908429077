export default {
  tabsColoringLencor: ["Сплошное", "Градиентное", "Усиливающее контраст"],
  tabsColoringBBGR: ["Сплошное", "Короткий Градиент", "Стандартный Градиент", "Длинный Градиент", "Трендовое"],
  tabsColorXperio: ["Сплошные", "Градиентные"],
  tabsColorTransitions: ["Классические", "Модные"],
  coloringLencor: {
    'Сплошное': {
      header: ["85%", "75%", "50%", "25%"],
      color: [
        {
          title: "Серый",
          items: [
            {id: "LvGray85", img: "solid_grey_85_lencorVision", percent: "85%"},
            {id: "LvGray75", img: "solid_grey_75_lencorVision", percent: "75%"},
            {id: "LvGray50", img: "solid_grey_50_lencorVision", percent: "50%"},
            {id: "LvGray25", img: "solid_grey_25_lencorVision", percent: "25%"},
          ],
        },
        {
          title: "Коричневый",
          items: [
            {
              id: "LvBrown85",
              img: "solid_brown_85_lencorVision",
              percent: "85%",
            },
            {
              id: "LvBrown75",
              img: "solid_brown_75_lencorVision",
              percent: "75%",
            },
            {
              id: "LvBrown50",
              img: "solid_brown_50_lencorVision",
              percent: "50%",
            },
            {
              id: "LvBrown25",
              img: "solid_brown_25_lencorVision",
              percent: "25%",
            },
          ],
        },
        {
          title: "Зеленый",
          items: [
            {
              id: "LvGreen85",
              img: "solid_green_85_lencorVision",
              percent: "85%",
            },
            {
              id: "LvGreen75",
              img: "solid_green_75_lencorVision",
              percent: "75%",
            },
            {
              id: "LvGreen50",
              img: "solid_green_50_lencorVision",
              percent: "50%",
            },
            {
              id: "LvGreen25",
              img: "solid_green_25_lencorVision",
              percent: "25%",
            },
          ],
        },
      ],
    },
    'Градиентное': {
      header: ["85-0%", "75-0%", "50-0%"],
      color: [
        {
          title: "Серый",
          items: [
            {
              id: "LvDeGray8500",
              img: "gradient_grey_85-0_lencorVision",
              percent: "85-0%",
            },
            {
              id: "LvDeGray7500",
              img: "gradient_grey_75-0_lencorVision",
              percent: "75-0%",
            },
            {
              id: "LvDeGray5000",
              img: "gradient_grey_50-0_lencorVision",
              percent: "50-0%",
            },
          ],
        },
        {
          title: "Коричневый",
          items: [
            {
              id: "LvDeBrown8500",
              img: "gradient_brown_85-0_lencorVision",
              percent: "85-0%",
            },
            {
              id: "LvDeBrown7500",
              img: "gradient_brown_75-0_lencorVision",
              percent: "75-0%",
            },
            {
              id: "LvDeBrown5000",
              img: "gradient_brown_50-0_lencorVision",
              percent: "50-0%",
            },
          ],
        },
        {
          title: "Зеленый",
          items: [
            {
              id: "LvDeGreen8500",
              img: "gradient_green_85-0_lencorVision",
              percent: "85-0%",
            },
            {
              id: "LvDeGreen7500",
              img: "gradient_green_75-0_lencorVision",
              percent: "75-0%",
            },
            {
              id: "LvDeGreen5000",
              img: "gradient _green_50-0_lencorVision",
              percent: "50-0%",
            },
          ],
        },
      ],
    },
    'Усиливающее контраст': {
      header: ["85%", "65%", "40%"],
      color: [
        {
          title: "Красно-коричневый",
          items: [
            {
              id: "LvRedBrown85",
              img: "contrast_red-brown_85_lencorVision",
              percent: "85%",
            },
            {id: 2, img: "", percent: "65%"},
            {id: 3, img: "", percent: "40%"},
          ],
        },
        {
          title: "Желтый",
          items: [
            {id: 5, img: "", percent: "85%"},
            {id: 6, img: "", percent: "65%"},
            {
              id: "LvYellow40",
              img: "contrast_yellow_40_lencorVision",
              percent: "40%",
            },
          ],
        },
        {
          title: "Оранжевый",
          items: [
            {id: 9, img: "", percent: "85%"},
            {
              id: "LvOrange65",
              img: "contrast_orange_65_lencorVision",
              percent: "65%",
            },
            {
              id: "LvOrange40",
              img: "contrast_orange_40_lencorVision",
              percent: "40%",
            },
          ],
        },
      ],
    },
  },
  coloringBBGR: {
    'Сплошное': {
      header: ['95%', '85%', '75%', '62%', '35%', '18%', '12%'],
      color: [
        {
          title: "Коричневый",
          items: [
            {id: "Brown95", img: "BBGR_Brown_Cat4", percent: "95%", indexes: ["15", "16", "167"]},
            {id: "Brown85", img: "BBGR_Brown_Cat3", percent: "85%", indexes: ["15", "16", "167"]},
            {id: "Brown75", img: "BBGR_Brown_Cat23", percent: "75%", indexes: ["15", "16", "167"]},
            {id: "Brown62", img: "BBGR_Brown_Cat2", percent: "62%", indexes: ["15", "16", "167"]},
            {id: "Brown35", img: "BBGR_Brown_Cat1", percent: "35%", indexes: ["15", "16", "167"]},
            {id: "Brown18", img: "BBGR_Brown_Cat0", percent: "18%", indexes: ["15", "16", "167"]},
            {id: "Brown12", img: "BBGR_Brown_Cat00", percent: "12%", indexes: ["15", "16", "167"]},
          ],
        },
        {
          title: "Черный",
          items: [
            {id: "Black95", img: "BBGR_Black_Cat4", percent: "95%", indexes: ["15", "16", "167"]},
            {id: "Black85", img: "BBGR_Black_Cat3", percent: "85%", indexes: ["15", "16", "167"]},
            {id: "Black75", img: "BBGR_Black_Cat23", percent: "75%", indexes: ["15", "16", "167"]},
            {id: "Black62", img: "BBGR_Black_Cat2", percent: "62%", indexes: ["15", "16", "167"]},
            {id: "Black35", img: "BBGR_Black_Cat1", percent: "35%", indexes: ["15", "16", "167"]},
            {id: "Black18", img: "BBGR_Black_Cat0", percent: "18%", indexes: ["15", "16", "167"]},
            {id: "Black12", img: "BBGR_Black_Cat00", percent: "12%", indexes: ["15", "16", "167"]},
          ],
        },
        {
          title: "Серо-зеленый",
          items: [
            {id: "GreyUS95", img: "BBGR_GreyUS_Cat4", percent: "95%", indexes: ["15", "16", "167"]},
            {id: "GreyUS85", img: "BBGR_GreyUS_Cat3", percent: "85%", indexes: ["15", "16", "167"]},
            {id: "GreyUS75", img: "BBGR_GreyUS_Cat23", percent: "75%", indexes: ["15", "16", "167"]},
            {id: "GreyUS62", img: "BBGR_GreyUS_Cat2", percent: "62%", indexes: ["15", "16", "167"]},
            {id: "GreyUS35", img: "BBGR_GreyUS_Cat1", percent: "35%", indexes: ["15", "16", "167"]},
            {id: "GreyUS18", img: "BBGR_GreyUS_Cat0", percent: "18%", indexes: ["15", "16", "167"]},
            {id: "GreyUS12", img: "BBGR_GreyUS_Cat00", percent: "12%", indexes: ["15", "16", "167"]},
          ],
        },
        {
          title: "Серый",
          items: [
            {id: "Grey95", img: "BBGR_Grey_Cat4", percent: "95%", indexes: ["15", "16", "167"]},
            {id: "Grey85", img: "BBGR_Grey_Cat3", percent: "85%", indexes: ["15", "16", "167"]},
            {id: "Grey75", img: "BBGR_Grey_Cat23", percent: "75%", indexes: ["15", "16", "167"]},
            {id: "Grey62", img: "BBGR_Grey_Cat2", percent: "62%", indexes: ["15", "16", "167"]},
            {id: "Grey35", img: "BBGR_Grey_Cat1", percent: "35%", indexes: ["15", "16", "167"]},
            {id: "Grey18", img: "BBGR_Grey_Cat0", percent: "18%", indexes: ["15", "16", "167"]},
            {id: "Grey12", img: "BBGR_Grey_Cat00", percent: "12%", indexes: ["15", "16", "167"]},
          ],
        },
        {
          title: "Синий",
          items: [
            {id: "BlueInk95", img: "BBGR_BlueInk_Cat4", percent: "95%", indexes: ["15", "167"]},
            {id: "BlueInk85", img: "BBGR_BlueInk_Cat3", percent: "85%", indexes: ["15", "167"]},
            {id: "BlueInk75", img: "BBGR_BlueInk_Cat23", percent: "75%", indexes: ["15"]},
            {id: "BlueInk62", img: "BBGR_BlueInk_Cat2", percent: "62%", indexes: ["15", "167"]},
            {id: "BlueInk35", img: "BBGR_BlueInk_Cat1", percent: "35%", indexes: ["15", "167"]},
            {},
            {id: "BlueInk12", img: "BBGR_BlueInk_Cat00", percent: "12%", indexes: ["15", "167"]},
          ],
        },
        {
          title: "Бордовый",
          items: [
            {id: "Bordeaux95", img: "BBGR_Bordeaux_Cat4", percent: "95%", indexes: ["15", "167"]},
            {id: "Bordeaux85", img: "BBGR_Bordeaux_Cat3", percent: "85%", indexes: ["15", "167"]},
            {},
            {id: "Bordeaux62", img: "BBGR_Bordeaux_Cat2", percent: "62%", indexes: ["15", "167"]},
            {id: "Bordeaux35", img: "BBGR_Bordeaux_Cat1", percent: "35%", indexes: ["15", "167"]},
            {},
            {id: "Bordeaux18", img: "BBGR_Bordeaux_Cat00", percent: "12%", indexes: ["15", "167"]},
          ],
        },
        {
          title: "Хаки",
          items: [
            {},
            {id: "Khaki85", img: "BBGR_Khaki_Cat3", percent: "85%", indexes: ["15"]},
            {}, {}, {}, {}, {}
          ],
        },
        {
          title: "Желтый",
          items: [
            {},
            {id: "Yellow85", img: "BBGR_Yellow_Cat3", percent: "85%", indexes: ["15", "16", "167"]},
            {},
            {id: "Yellow62", img: "BBGR_Yellow_Cat2", percent: "62%", indexes: ["15", "16", "167"]},
            {},
            {id: "Yellow12", img: "BBGR_Yellow_Cat0", percent: "18%", indexes: ["15", "16", "167"]},
            {}
          ],
        },
        {
          title: "Оранжевый",
          items: [
            {},
            {},
            {},
            {id: "Orange62", img: "BBGR_Orange_Cat2", percent: "62%", indexes: ["15"]},
            {}, {}, {}
          ],
        },
        {
          title: "Розовато-лиловый",
          items: [
            {},
            {},
            {},
            {},
            {id: "Mauve35", img: "BBGR_Mauve_Cat1", percent: "35%", indexes: ["15"]},
            {}, {}
          ],
        },
        {
          title: "Парма",
          items: [
            {},
            {},
            {},
            {},
            {id: "Parma35", img: "BBGR_Parma_Cat1", percent: "35%", indexes: ["15", "167"]},
            {}, {}
          ],
        },
        {
          title: "Голубой",
          items: [
            {},
            {},
            {},
            {},
            {id: "Blue35", img: "BBGR_Blue_Cat1", percent: "35%", indexes: ["15"]},
            {}, {}
          ],
        },
        {
          title: "Розовый",
          items: [
            {},
            {},
            {},
            {},
            {id: "Pink35", img: "BBGR_Pink_Cat1", percent: "35%", indexes: ["15"]},
            {}, {}
          ],
        },
        {
          title: "Золотой",
          items: [
            {},
            {},
            {},
            {},
            {id: "SecretGold35", img: "BBGR_SecretGold_Cat1", percent: "35%", indexes: ["15"]},
            {}, {}
          ],
        },
      ],
    },
    'Короткий Градиент': {
      header: ['85%', '62%', '35%'],
      color: [
        {
          title: "Коричневый",
          items: [
            {
              id: "DeBrown85",
              img: "BBGR_Brown_DegStand_Cat3",
              percent: "85%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeBrown62",
              img: "BBGR_Brown_DegShort_Cat2",
              percent: "62%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeBrown35",
              img: "BBGR_Brown_DegStand_Cat1",
              percent: "35%",
              indexes: ["15", "16", "167"]
            },
          ],
        },
        {
          title: "Черный",
          items: [
            {
              id: "DeBlack85",
              img: "BBGR_Black_DegShort_Cat3",
              percent: "85%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeBlack62",
              img: "BBGR_Black_DegShort_Cat2",
              percent: "62%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeBlack35",
              img: "BBGR_Black_DegShort_Cat1",
              percent: "35%",
              indexes: ["15", "16", "167"]
            },
          ],
        },
        {
          title: "Серо-зеленый",
          items: [
            {
              id: "DeGreyUS85",
              img: "BBGR_GreyUS_DegShort_Cat3",
              percent: "85%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeGreyUS62",
              img: "BBGR_GreyUS_DegShort_Cat2",
              percent: "62%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeGreyUS35",
              img: "BBGR_GreyUS_DegShort_Cat1",
              percent: "35%",
              indexes: ["15", "16", "167"]
            },
          ],
        },
        {
          title: "Серый",
          items: [
            {
              id: "DeGrey85",
              img: "BBGR_Grey_DegShort_Cat3",
              percent: "85%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeGrey62",
              img: "BBGR_Grey_DegShort_Cat2",
              percent: "62%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeGrey35",
              img: "BBGR_Grey_DegShort_Cat1",
              percent: "35%",
              indexes: ["15", "16", "167"]
            },
          ],
        },
        {
          title: "Синий",
          items: [
            {
              id: "DeBlueInk85",
              img: "BBGR_BlueInk_DegShort_Cat3",
              percent: "85%",
              indexes: ["15", "167"]
            }, {}, {}
          ],
        },
        {
          title: "Бордовый",
          items: [
            {
              id: "DeBordeaux85",
              img: "BBGR_Bordeaux_DegShort_Cat3",
              percent: "85%",
              indexes: ["15", "167"]
            }, {}, {}
          ],
        },
      ],
    },
    'Стандартный Градиент': {
      header: ['85%', '62%', '35%'],
      color: [
        {
          title: "Коричневый",
          items: [
            {
              id: "DeBrown85",
              img: "BBGR_Brown_DegStand_Cat3",
              percent: "85%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeBrown62",
              img: "BBGR_Brown_DegStand_Cat2",
              percent: "62%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeBrown35",
              img: "BBGR_Brown_DegStand_Cat1",
              percent: "35%",
              indexes: ["15", "16", "167"]
            },
          ],
        },
        {
          title: "Черный",
          items: [
            {
              id: "DeBlack85",
              img: "BBGR_Black_DegStand_Cat3",
              percent: "85%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeBlack62",
              img: "BBGR_Black_DegStand_Cat2",
              percent: "62%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeBlack35",
              img: "BBGR_Black_DegStand_Cat1",
              percent: "35%",
              indexes: ["15", "16", "167"]
            },
          ],
        },
        {
          title: "Серо-зеленый",
          items: [
            {
              id: "DeGreyUS85",
              img: "BBGR_GreyUS_DegStand_Cat3",
              percent: "85%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeGreyUS62",
              img: "BBGR_GreyUS_DegStand_Cat2",
              percent: "62%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeGreyUS35",
              img: "BBGR_GreyUS_DegStand_Cat1",
              percent: "35%",
              indexes: ["15", "16", "167"]
            },
          ],
        },
        {
          title: "Серый",
          items: [
            {
              id: "DeGrey85",
              img: "BBGR_Grey_DegStand_Cat3",
              percent: "85%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeGrey62",
              img: "BBGR_Grey_DegStand_Cat2",
              percent: "62%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeGrey35",
              img: "BBGR_Grey_DegStand_Cat1",
              percent: "35%",
              indexes: ["15", "16", "167"]
            },
          ],
        },
        {
          title: "Синий",
          items: [
            {
              id: "DeBlueInk85",
              img: "BBGR_BlueInk_DegStand_Cat3",
              percent: "85%",
              indexes: ["15", "167"]
            }, {}, {}
          ],
        },
        {
          title: "Бордовый",
          items: [
            {
              id: "DeBordeaux85",
              img: "BBGR_Bordeaux_DegStand_Cat3",
              percent: "85%",
              indexes: ["15", "167"]
            }, {}, {}
          ],
        },
        {
          title: "Розовато-лиловый",
          items: [
            {}, {},
            {
              id: "DeMauve35",
              img: "BBGR_Mauve_DegStand_Cat1",
              percent: "35%",
              indexes: ["15"]
            }
          ],
        },
        {
          title: "Парма",
          items: [
            {}, {},
            {
              id: "DeParma35",
              img: "BBGR_Parma_DegStand_Cat1",
              percent: "35%",
              indexes: ["15", "167"]
            }
          ],
        },
        {
          title: "Голубой",
          items: [
            {}, {},
            {
              id: "DeBlue35",
              img: "BBGR_Blue_DegStand_Cat1",
              percent: "35%",
              indexes: ["15"]
            }
          ],
        },
        {
          title: "Розовый",
          items: [
            {}, {},
            {
              id: "DePink35",
              img: "BBGR_Pink_DegStand_Cat1",
              percent: "35%",
              indexes: ["15"]
            }
          ],
        },
        {
          title: "Розовато-лиловый",
          items: [
            {}, {},
            {
              id: "DeSecretGold35",
              img: "BBGR_SecretGold_DegStand_Cat1",
              percent: "35%",
              indexes: ["15"]
            }
          ],
        },
      ],
    },
    'Длинный Градиент': {
      header: ['85%', '62%', '35%'],
      color: [
        {
          title: "Коричневый",
          items: [
            {
              id: "DeBrown85",
              img: "BBGR_Brown_DegLong_Cat3",
              percent: "85%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeBrown62",
              img: "BBGR_Brown_DegLong_Cat2",
              percent: "62%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeBrown35",
              img: "BBGR_Brown_DegLong_Cat1",
              percent: "35%",
              indexes: ["15", "16", "167"]
            },
          ],
        },
        {
          title: "Черный",
          items: [
            {
              id: "DeBlack85",
              img: "BBGR_Black_DegLong_Cat3",
              percent: "85%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeBlack62",
              img: "BBGR_Black_DegLong_Cat2",
              percent: "62%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeBlack35",
              img: "BBGR_Black_DegLong_Cat1",
              percent: "35%",
              indexes: ["15", "16", "167"]
            },
          ],
        },
        {
          title: "Серо-зеленый",
          items: [
            {
              id: "DeGreyUS85",
              img: "BBGR_GreyUS_DegLong_Cat3",
              percent: "85%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeGreyUS62",
              img: "BBGR_GreyUS_DegLong_Cat2",
              percent: "62%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeGreyUS35",
              img: "BBGR_GreyUS_DegLong_Cat1",
              percent: "35%",
              indexes: ["15", "16", "167"]
            },
          ],
        },
        {
          title: "Серый",
          items: [
            {
              id: "DeGrey85",
              img: "BBGR_Grey_DegLong_Cat3",
              percent: "85%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeGrey62",
              img: "BBGR_Grey_DegLong_Cat2",
              percent: "62%",
              indexes: ["15", "16", "167"]
            },
            {
              id: "DeGrey35",
              img: "BBGR_Grey_DegLong_Cat1",
              percent: "35%",
              indexes: ["15", "16", "167"]
            },
          ],
        },
        {
          title: "Синий",
          items: [
            {
              id: "DeBlueInk85",
              img: "BBGR_BlueInk_DegLong_Cat3",
              percent: "85%",
              indexes: ["15", "167"]
            }, {}, {}
          ],
        },
        {
          title: "Бордовый",
          items: [
            {
              id: "DeBordeaux85",
              img: "BBGR_Bordeaux_DegLong_Cat3",
              percent: "85%",
              indexes: ["15", "167"]
            }, {}, {}
          ],
        },
        {
          title: "Сапфир",
          items: [
            {
              id: "DeSapphire85",
              img: "BBGR_Sapphire_DegLong_Cat3",
              percent: "85%",
              indexes: ["15", "167"]
            }, {}, {}
          ],
        },
        {
          title: "Бирюзовый",
          items: [
            {
              id: "DeTurquiose85",
              img: "BBGR_Turquoise_DegLong_Cat3",
              percent: "85%",
              indexes: ["15", "167"]
            }, {}, {}
          ],
        },
        {
          title: "Янтарь",
          items: [
            {}, {
              id: "DeAmber62",
              img: "BBGR_Amber_DegLong_Cat2",
              percent: "62%",
              indexes: ["15", "167"]
            }, {}
          ],
        },
      ],
    },
    Трендовое: {
      header: ['95%', '85%', '75%', '62%', '35%', '18%', '12%'],
      color: [
        {
          title: "Коричневый",
          items: [
            {id: "Brown95", img: "BBGR_Brown_Cat4", percent: "95%", indexes: ["15"]},
            {id: "Brown85", img: "BBGR_Brown_Cat3", percent: "85%", indexes: ["15"]},
            {id: "Brown75", img: "BBGR_Brown_Cat23", percent: "75%", indexes: ["15"]},
            {id: "Brown62", img: "BBGR_Brown_Cat2", percent: "62%", indexes: ["15"]},
            {id: "Brown35", img: "BBGR_Brown_Cat1", percent: "35%", indexes: ["15"]},
            {id: "Brown18", img: "BBGR_Brown_Cat0", percent: "18%", indexes: ["15"]},
            {id: "Brown12", img: "BBGR_Brown_Cat00", percent: "12%", indexes: ["15"]},
          ],
        },
        {
          title: "Черный",
          items: [
            {id: "Black95", img: "BBGR_Black_Cat4", percent: "95%", indexes: ["15"]},
            {id: "Black85", img: "BBGR_Black_Cat3", percent: "85%", indexes: ["15"]},
            {id: "Black75", img: "BBGR_Black_Cat23", percent: "75%", indexes: ["15"]},
            {id: "Black62", img: "BBGR_Black_Cat2", percent: "62%", indexes: ["15"]},
            {id: "Black35", img: "BBGR_Black_Cat1", percent: "35%", indexes: ["15"]},
            {id: "Black18", img: "BBGR_Black_Cat0", percent: "18%", indexes: ["15"]},
            {id: "Black12", img: "BBGR_Black_Cat00", percent: "12%", indexes: ["15"]},
          ],
        },
        {
          title: "Серо-зеленый",
          items: [
            {id: "GreyUS95", img: "BBGR_GreyUS_Cat4", percent: "95%", indexes: ["15"]},
            {id: "GreyUS85", img: "BBGR_GreyUS_Cat3", percent: "85%", indexes: ["15"]},
            {id: "GreyUS75", img: "BBGR_GreyUS_Cat23", percent: "75%", indexes: ["15"]},
            {id: "GreyUS62", img: "BBGR_GreyUS_Cat2", percent: "62%", indexes: ["15"]},
            {id: "GreyUS35", img: "BBGR_GreyUS_Cat1", percent: "35%", indexes: ["15"]},
            {id: "GreyUS18", img: "BBGR_GreyUS_Cat0", percent: "18%", indexes: ["15"]},
            {id: "GreyUS12", img: "BBGR_GreyUS_Cat00", percent: "12%", indexes: ["15"]},
          ],
        },
        {
          title: "Желтый",
          items: [
            {},
            {id: "Yellow85", img: "BBGR_Yellow_Cat3", percent: "85%", indexes: ["15"]},
            {},
            {id: "Yellow62", img: "BBGR_Yellow_Cat2", percent: "62%", indexes: ["15"]},
            {},
            {id: "Yellow12", img: "BBGR_Yellow_Cat0", percent: "18%", indexes: ["15"]},
            {}
          ],
        },
        {
          title: "Оранжевый",
          items: [
            {},
            {},
            {},
            {id: "Orange62", img: "BBGR_Orange_Cat2", percent: "62%", indexes: ["15"]},
            {}, {}, {}
          ],
        },
        {
          title: "Розовато-лиловый",
          items: [
            {},
            {},
            {},
            {},
            {id: "Mauve35", img: "BBGR_Mauve_Cat1", percent: "35%", indexes: ["15"]},
            {}, {}
          ],
        },
        {
          title: "Парма",
          items: [
            {},
            {},
            {},
            {},
            {id: "Parma35", img: "BBGR_Parma_Cat1", percent: "35%", indexes: ["15"]},
            {}, {}
          ],
        },
        {
          title: "Голубой",
          items: [
            {},
            {},
            {},
            {},
            {id: "Blue35", img: "BBGR_Blue_Cat1", percent: "35%", indexes: ["15"]},
            {}, {}
          ],
        },
        {
          title: "Розовый",
          items: [
            {},
            {},
            {},
            {},
            {id: "Pink35", img: "BBGR_Pink_Cat1", percent: "35%", indexes: ["15"]},
            {}, {}
          ],
        },
        {
          title: "Золотой",
          items: [
            {},
            {},
            {},
            {},
            {id: "SecretGold35", img: "BBGR_SecretGold_Cat1", percent: "35%", indexes: ["15"]},
            {}, {}
          ],
        },
      ],
    },
  },
  colorXperio: {
    0: {
      header: [],
      color: [
        {
          title: "Серый",
          items: [{id: "xGray", img: "colour_solid_серый_xperio_bbgr"}],
        },
        {
          title: "Коричневый",
          items: [{id: "xBrown", img: "colour_solid_коричневый_xperio_bbgr"}],
        },
        {
          title: "Серо-зеленый",
          items: [
            {id: "xGrayGreen", img: "colour_solid_серо-зеленый_xperio_bbgr"},
          ],
        },
        {
          title: "Сливовый",
          items: [{id: "xPlum", img: "colour_solid_сливовый_xperio_bbgr"}],
        },
        {
          title: "Синий",
          items: [{id: "xBlue", img: "colour_solid_синий_xperio_bbgr"}],
        },
        {
          title: "Янтарь",
          items: [{id: "xAmber", img: "colour_solid_янтарь_xperio_bbgr"}],
        },
        {
          title: "Хаки",
          items: [{id: "xKhaki", img: "colour_solid_хаки_xperio_bbgr"}],
        },
      ],
    },
    1: {
      header: [],
      color: [
        {
          title: "Серый",
          items: [{id: "xDeBrown", img: "colour_gradient_серый_xperio_bbgr"}],
        },
        {
          title: "Коричневый",
          items: [
            {id: "xDeGray", img: "colour_gradient_коричневый_xperio_bbgr"},
          ],
        },
        {
          title: "Серо-зеленый",
          items: [
            {id: "xDeGrayGreen", img: "colour_gradient_серо-зеленый_xperio"},
          ],
        },
        {
          title: "Сливовый",
          items: [{id: "xDePlum", img: "colour_gradient_сливовый_xperio_bbgr"}],
        },
        {
          title: "Синий",
          items: [{id: "xDeBlue", img: "colour_gradient_синий_xperio_bbgr"}],
        },
        {
          title: "Янтарь",
          items: [{id: "xDeAmber", img: "colour_gradient_янтарь_xperio_bbgr"}],
        },
        {
          title: "Хаки",
          items: [
            {id: "xDeKhaki", img: "colour_gradient_хаки градиент_xperio_bbgr"},
          ],
        },
      ],
    },
  },
  colorTransitions: {
    0: {
      header: [],
      color: [
        {
          title: "Серый",
          items: [
            {
              id: "Tr8Gray",
              img: "colour_ classical_серый_transitions_bbgr_lencorVision",
            },
          ],
        },
        {
          title: "Коричневый",
          items: [
            {id: "Tr8Brown", img: "colour_ classical_коричневый_transitions"},
          ],
        },
        {
          title: "Серо-зеленый",
          items: [
            {
              id: "Tr8GrayGreen",
              img: "colour_ classical_серо-зеленый_transitions_bbgr_lencorVision",
            },
          ],
        },
      ],
    },
    1: {
      header: [],
      color: [
        {
          title: "Изумруд",
          items: [
            {
              id: "TrFsEmerald",
              img: "colour_fashion_изумруд_transitions_bbgr_lencorVision",
            },
          ],
        },
        {
          title: "Аметист",
          items: [
            {
              id: "TrFsSappfire",
              img: "colour_fashion_аметист_transitions_bbgr_lencorVision",
            },
          ],
        },
        {
          title: "Сапфир",
          items: [
            {
              id: "TrFsAmethyst",
              img: "colour_fashion_сапфир_transitions_bbgr_lencorVision",
            },
          ],
        },
        {
          title: "Янтарь",
          items: [
            {
              id: "TrFsAmber",
              img: "colour_fashion_янтарь_transitions_bbgr_lencorVision",
            },
          ],
        },
      ],
    },
  },
  colorTransitionsXTRActive: {
    0: {
      header: [],
      color: [
        {
          title: "Серый",
          items: [
            {id: "TrXtGray", img: "colour_серый_transitions_XTRActive_bbgr"},
          ],
        },
        {
          title: "Коричневый",
          items: [
            {
              id: "TrXtBrown",
              img: "colour_коричневый_transitions_XTRActive_bbgr",
            },
          ],
        },
        {
          title: "Серо-зеленый",
          items: [
            {
              id: "TrXtGrayGreen",
              img: "colour_серо-зеленый_transitions_XTRActive_bbgr",
            },
          ],
        },
      ],
    },
  },
  colorSunsensy: {
    0: {
      header: [],
      color: [
        {
          title: "Серый",
          items: [
            {id: "LvSunSGray", img: "colour_серый_Sunsensy_LencorVision"},
          ],
        },
        {
          title: "Коричневый",
          items: [
            {id: "LvSunSBrown", img: "colour_коричневый_Sunsensy_LencorVision"},
          ],
        },
      ],
    },
  },
}
