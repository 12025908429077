export default {
  setClient(_state: any, client: any) {
    _state.client = client
  },
  setClients(_state: any, clients: any) {
    _state.clients = clients
  },
  setClientsPagination(_state: any, meta: any) {
    _state.clientsPagination = {
      total: meta.number_of_records,
      page: meta.page,
      countItem: meta.records_on_page,
    }
  },
  setValue(_state: any, obj: any) {
    const key = Object.keys(obj)[0]
    _state[key] = obj[key]
  },
  setLeaders(_state: any, value: any) {
    _state.leaders = value
  },
}
