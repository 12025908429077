import Vue from "vue"
import { setLensParametersPayloadType } from "@/store/modules/orderRX/types"
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEqual } from "lodash"

export default {
  setTriggerPage(_state: any, data: string) {
    _state.triggerPage = data
  },
  setLens({order}: any, data: object) {
    order.lens = data
  },
  setClaimId(_state: any, id: string) {
    _state.claimId= id
  },
  setChosenLens(_state: any, id: object[]) {
    _state.chosenLens = id
  },

  setIsFrameParams(_state: any, value: boolean ){
    _state.isFrameParams = value
  },

  setOrderRXFilters(_state: any, data: any) {
    const {res: requestResult } = data;

    const areFiltersEqual = isEqual(_state.orderRXFilters, requestResult.filters)
    const areResultsEqual = isEqual(_state.orderRXLenses, requestResult.results)

    if (areFiltersEqual && areResultsEqual) return;

    if (areFiltersEqual) {
      _state.orderRXLenses = [
        ..._state.orderRXLenses,
        ...requestResult.results,
      ];
    } else {
      _state.orderRXFilters = requestResult.filters;
      _state.orderRXLenses = requestResult.results;
    }

    _state.totalCount = requestResult.count;
  },
  setOrderRXRules(_state: any, data: any) {
    _state.orderRXRules = data
  },
  setOrderRXFiltersChecked(_state: any, data: any) {
    _state.orderRXFiltersChecked.find((el: any) => el.type === data.type)[data.label] = data.value
  },
  setInitialOrderRXFiltersChecked(_state: any, data: any) {
    _state.orderRXFiltersChecked = data
  },
  setFilterId(_state: any, id: number | null) {
    _state.filtersId = id
  },
  setLensManufacturer(_state: any, data: string) {
    _state.manufacturer = data
  },
  setLensParameters(_state: any, payload: setLensParametersPayloadType) {
    Vue.set(_state.lensParameters, payload.key, payload.data)
  },
  setBaseValue({order}: any, data: string | number) {
    order.baseValue = data
  },
  setDiameter({order}: any, data: string | number) {
    order.diameter = data
  },
  setFrameType({order}: any, data: string | number) {
    order.frameType = data
  },
  setFrameTypeValue({order}: any, data: string | number) {
    order.frameTypeValue = data
  },
  setFrameModel({order}: any, data: string) {
    order.frameModel = data
  },
  setProductionType({order}: any, data: string | number) {
    order.productionType = data
  },
  setRepairType({order}: any, data: string[]) {
    order.repairType = data
  },
  setPaintingType({order}: any, data: string | number) {
    order.paintingType = data
  },
  setAdditionalType({order}: any, data: string[]) {
    order.additionalType = data
  },
  setFrameColoring({order}: any, data: boolean) {
    order.frameColoring = data
  },
  setUVProtect({order}: any, data: boolean) {
    order.UVProtect = data
  },
  setComments({order}: any, data: string) {
    order.comments = data
  },
  setFrameForm({order}: any, data: object) {
    order.frameForm = data
  },
  setArm({order}: any, data: object) {
    order.arm = data
  },
  setRecipeDetails({order}: any, data: any) {
    order.recipeDetails.recipeDetailsTableRE = data.recipeDetailsTableRE.map(
      (el: any) => ({title: el.title, value: el.value})
    )
    order.recipeDetails.recipeDetailsTableLE = data.recipeDetailsTableLE.map(
      (el: any) => ({title: el.title, value: el.value})
    )
    order.recipeDetails.prismTableRowsRE = data.prismTableRowsRE.map(
      (el: any) => ({title: el.title, value: el.value})
    )
    order.recipeDetails.prismTableRowsLE = data.prismTableRowsLE.map(
      (el: any) => ({title: el.title, value: el.value})
    )
    order.recipeDetails.decentrationTableRowsRE =
      data.decentrationTableRowsRE.map((el: any) => ({
        title: el.title,
        value: el.value,
      }))
    order.recipeDetails.decentrationTableRowsLE =
      data.decentrationTableRowsLE.map((el: any) => ({
        title: el.title,
        value: el.value,
      }))
  },
  setFrameParameters({order}: any, data: any) {
    order.frameParameters.frameParametersMiddle =
      data.frameParametersMiddle.map((el: any) => ({
        title: el.title,
        value: el.value,
      }))
    order.frameParameters.frameParametersRowsRE =
      data.frameParametersRowsRE.map((el: any) => ({
        title: el.title,
        value: el.value,
      }))
    order.frameParameters.frameParametersRowsLE =
      data.frameParametersRowsLE.map((el: any) => ({
        title: el.title,
        value: el.value,
      }))
  },

  setMorphologicalParameters({order}: any, data: any) {
    Vue.set(order.morphologicalParameters, data.key, data.value)
  },

  setActivity({order}: any, data: object) {
    order.poll = {...order.poll, ...data}
  },

  setErrors(_state: any, data: any) {
    if (Object.keys(data).length) {
      _state.errors[data.key] = data.value
    } else {
      _state.errors = {}
    }
  },
  setDeliveryPoint(_state: any, id: string) {
    _state.selectedDeliveryPoint = id
  },
  setOrderConfirm(_state: any, confirm: boolean) {
    _state.orderConfirm = confirm
  },
  setDifferentLens(_state: any, different: boolean) {
    _state.differentLens = different
  },
  setOrderForEditLoaded(_state: any, loaded: boolean) {
    _state.orderForEditLoaded = loaded
  },
  clearStateData(_state: any, { clearLenses, clearRules } = { clearLenses: true, clearRules: true }) {
    _state.order = {
      lens: clearLenses ? [{ item_data: { oculus: 'OS' } }, { item_data: { oculus: 'OD' } }] : _state.order.lens,
      baseValue: null,
      diameter: null,
      frameType: null,
      frameModel: "",
      productionType: null,
      repairType: [],
      paintingType: null,
      additionalType: [],
      frameColoring: "",
      UVProtect: false,
      comments: "",
      frameForm: {},
      arm: "",
      recipeDetails: {
        recipeDetailsTableRE: null,
        recipeDetailsTableLE: null,
        prismTableRowsRE: null,
        prismTableRowsLE: null,
        decentrationTableRowsRE: null,
        decentrationTableRowsLE: null,
      },
      frameParameters: {
        frameParametersMiddle: null,
        frameParametersRowsRE: null,
        frameParametersRowsLE: null,
      },
      morphologicalParameters: {
        clientGrowth: "",
        distanceToNear: "",
      },
      poll: {
        distantUse: "",
        averageUse: "",
        closeUse: "",
        recommendation: "",
      },
    }
    _state.orderRXRules = clearRules ? {} : _state.orderRXRules
  },
}
