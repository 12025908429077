import {getAvatarLink} from "@/utils/url"

export default {
  getUsers: (_state: any) =>
    _state?.users?.data.map((user: any) => ({
      key: user.user_data.id,
      guid: user.user_data.number_1c_id,
      avatar: getAvatarLink(user.user_data.userpic_url) || "",
      fio: `${user.user_data.lastname} ${user.user_data.name} ${user.user_data.middlename}`,
      name: user.user_data.name || "",
      lastname: user.user_data.lastname || "",
      middlename: user.user_data.middlename || "",
      subdivision: user.user_data.subdivision || "",
      subdivisionName: user.user_data.subdivision_name || "",
      position: user.user_data.position || "",
      roleTitle: user.role_name || "",
      role: user.role_data,
      phone: user.user_data.phone || "",
      email: user.user_data.email || "",
      sendInvitation: false,
      status: user.user_data.is_active,
    })),
  getUser: (_state: any) => _state.user,
  getDepts: (_state: any) => _state.departments.subdivisions,
  getRoles: (_state: any) => _state.roles.data,
  getUsersPagination: (_state: any) => ({
    total: +_state.users.meta.number_of_records,
    current: +_state.users.meta.page,
    pageSize: +_state.users.meta.records_on_page,
  }),
  getDeptsPagination: (_state: any) => ({
    total: +_state.departments.meta.number_of_records,
    current: +_state.departments.meta.page,
    pageSize: +_state.departments.meta.records_on_page,
  }),
  getRolesPagination: (_state: any) => ({
    total: +_state.roles.meta.number_of_records,
    current: +_state.roles.meta.page || 1,
    pageSize: +_state.roles.meta.records_on_page,
  }),
}
