import {apiClient} from "@/api/setupApi"

const POST_RECIPT_PARAMETERS = `v1/catalog/lens-picker`
const UPLOAD_LENS_PICKER_CATALOG = `v1/catalog/lens-picker/upload`
const ORDER_LENSES = `v1/catalog/lens-picker/order`

export default {
  postRecipeParameters(data: any): Promise<any> {
    return apiClient.post(POST_RECIPT_PARAMETERS, data)
  },

  uploadLenspickerCatalog(file: any): Promise<any> {
    return apiClient.post(UPLOAD_LENS_PICKER_CATALOG, file)
  },

  orderLenses(data: any): Promise<any> {
    return apiClient.post(ORDER_LENSES, data)
  },
}
