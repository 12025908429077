export default {
  setOrders(_state: any, value: object[]) {
    _state.orders = value
  },

  setDeliveryAddresses(_state: any, value: object[]) {
    _state.deliveryAddresses = value
  },

  setFrameColoring(_state: any, value: object) {
    _state.frameColoring = value
  },

  setCreateGlassesDetailsField(_state: any, { field, value }: { field: string, value: any }) {
    _state.createGlassesDetails[field] = value
  },

  setCreateGlassesDetails(_state: any, value: object) {
    _state.createGlassesDetails = value
  },

  setDeliveryCompanies(_state: any, value: object[]) {
    _state.deliveryCompanies = value
  },

  addOrderToCart({orders}: any, value: string) {
    if (!orders.includes(value)) {
      orders.push(value)
    }
  },
  deleteOrderToCart(_state: any, value: string) {
    delete _state.orders[value]
  },
  setCurrentOrder(_state: any, value: string) {
    _state.currentOrder = value
  },
  setItemsCountInCart(_state: any, { orders, orders_rx, orderitems, orderitems_rx }: any) {
    const allOrders = orders + orders_rx
    const allItems = orderitems + orderitems_rx
    _state.itemsCount = allItems ? `${allItems}${allOrders === 1 ? '' : `/${allOrders}`}` : null
    _state.itemsWarehouseCount = orderitems ? `${orderitems}${orders === 1 ? '' : `/${orders}`}` : null
    _state.itemsRXCount = orderitems_rx ? `${orderitems_rx}${orders_rx === 1 ? '' : `/${orders_rx}`}` : null
  },
  setCurrentTab(_state: any, tab: string) {
    _state.currentTab = tab
  }
}
