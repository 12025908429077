import {OrderRXFilters} from "./types"

export default {
  triggerPage: "lens",
  manufacturer: ['BBGR'],
  selectedDeliveryPoint: "",
  claimId: null,
  orderRXLenses: [],
  orderRXFilters: {} as OrderRXFilters,
  orderRXRules: {},
  chosenLens: [{}, {}],
  filtersId: null,
  totalCount: 0,
  isFrameParams: false,
  orderRXFiltersChecked: [
      {
      type: "l",
      manufacture: [],
      style: "",
      index: [],
      design: "",
      property: null,
      tint: null,
      color: "",
      // search_string: "",
    },
    {
      type: "r",
      manufacture: [],
      style: "",
      index: [],
      design: "",
      property: null,
      tint: null,
      color: "",
      // search_string: "",
    }
    ],
  lensParameters: {
    coloring_l: "",
    coloring_r: "",
  },
  differentLens: false,
  order: {
    lens: [{}, {}],
    baseValue: null,
    diameter: null,
    frameType: null,
    frameForm: {},
    arm: "",
    recipeDetails: {
      recipeDetailsTableRE: null,
      recipeDetailsTableLE: null,
      prismTableRowsRE: null,
      prismTableRowsLE: null,
      decentrationTableRowsRE: null,
      decentrationTableRowsLE: null,
    },
    frameParameters: {
      frameParametersMiddle: null,
      frameParametersRowsRE: null,
      frameParametersRowsLE: null,
    },
    morphologicalParameters: {
      clientGrowth: "",
      distanceToNear: "",
    },
    poll: {
      distantUse: "",
      averageUse: "",
      closeUse: "",
      recommendation: "",
    },
  },
  errors: {},
  orderConfirm: false,
  orderForEditLoaded: false,
  frameTypes: [
    {label: "Ободковая (0.8 мм)", value: "1"},
    {label: "На леске (1.8 мм)", value: "2"},
    {label: "Безободковая (2.00 мм)", value: "3"},
    {label: "Точное значение", value: "4"},
  ],
  productionOptions: [
    {label: "Ободок (пластик)", value: "1"},
    {label: "Ободок (металл)", value: "2"},
    {label: "Леска (полуободковая)", value: "3"},
    {label: "Винты (безободковая)", value: "4"},
  ],
  repairOptions: [
    {label: "Замена втулок", value: "1"},
    {label: "Выправка оправы", value: "2"},
    {label: "Замена винтов", value: "3"},
    {label: "Замена лески", value: "4"},
    {label: "Замена наконечников", value: "5"},
    {label: "Замена носоупоров", value: "6"},
    {label: "Чистка оправы", value: "7"},
  ],
  additionalOptions: [
    {label: "Полировка торца линзы", value: "1"},
    {label: "Срочное изготовление", value: "2"},
  ],
}
