// export const PRIV = {
//   // Сотрудники, клиенты, роли
//   emp_adm: "emp_adm", // Администрирование сотрудников
//   role_adm: "role_adm", // Администрирование ролей
//   client_adm: "client_adm", // Администрирование клиентов

//   // Заказы, каталог
//   acc_order_wrh_les: "acc_order_wrh_les", // Доступ к заказу складских линз
//   acc_orders_rx: "acc_orders_rx", // Доступ к заказу RX
//   acc_order_history: "acc_order_history", // Доступ к истории заказов
//   acc_directory: "acc_directory", // Каталог

//   // Цены, остатки
//   rec_price_vis: "rec_price_vis", // Видимость рекомендованных цен
//   price_vis_order: "price_vis_order", // Видимость цен в подтвержденных заказах
//   warehouse_vis: "warehouse_vis", // Видимость складских остатков

//   // Другие права
//   acc_main_page: "acc_main_page", // Доступ к главной странице
//   acc_contact: "acc_contact", // Доступ к контактам
//   acc_polls: "acc_polls", // Доступ к опросам
//   acc_fin_info: "acc_fin_info", // Доступ к финансовой информации
//   mrk_prm: "mrk_prm", // Маркетинговые акции и материалы
//   feedback: "feedback", // Обратная связь
//   profile_adm: "profile_adm", // Администрирование профиля
//   show_client: "show_client", // Виден клиенту
// }

export const PRIV = {
  /* Сотрудники, клиенты, роли */
  emp_adm: "emp_adm", // Администрирование сотрудников
  // Список - Subdivision,Group
  // Подразделения - Subdivision, Users
  role_adm: "role_adm", // Администрирование ролей
  // Group
  client_adm: "client_adm", // Администрирование клиентов *проверить везде этот свойство
  // Список - Clients
  // Сотрудники -  Clients, DeliveryPoint,  Group
  // Салоны оптики -  Subdivision, DeliveryPoint, Subdivision, Clients

  /* Заказы, каталог */
  acc_order_wrh_les: "acc_order_wrh_les", // Доступ к заказу складских линз
  // Orders, OrdersRX, Nomenclatures, Segments,OrderRecipes
  acc_orders_rx: "acc_orders_rx", // Доступ к заказу RX
  // NomenclaturesRX - в каталоге
  // OrdersRX, OrderItemsRX - в корзине
  acc_order_history: "acc_order_history", // Доступ к истории заказов
  // Subdivision, DeliveryPoint, OrderRecipes
  acc_directory: "acc_directory", // Каталог
  // Nomenclatures, Segments

  /* Цены, остатки */
  rec_price_vis: "rec_price_vis", // Видимость рекомендованных цен
  // ???
  price_vis_order: "price_vis_order", // Видимость цен в подтвержденных заказах
  // ???
  approve_orders: "approve_orders", // Обязательное согласование заказов
  // ???
  warehouse_vis: "warehouse_vis", // Видимость складских остатков
  // ???

  /* Другие права */
  acc_main_page: "acc_main_page", // Доступ к главной странице
  // Доступен всем после логина
  acc_contact: "acc_contact", // Доступ к контактам
  // Clients
  acc_polls: "acc_polls", // Доступ к опросам
  // ???
  acc_fin_info: "acc_fin_info", // Доступ к финансовой информации
  // Discount, PaymentSchedule
  mrk_prm: "mrk_prm", // Маркетинговые акции и материалы
  //  Promotions, Promocodes
  feedback: "feedback", // Обратная связь
  // Доступен всем после логина
  profile_adm: "profile_adm", // Администрирование профиля
  // Users(change_users)
  show_client: "show_client", // Виден клиенту
  // -
}

// Доступ к заказу складских линз:
// Orders, OrdersRX, Nomenclatures, Segments,OrderRecipes

// Администрирование клиентов:
// Список - Clients
// Сотрудники -  Clients, DeliveryPoint,  Group
// Салоны оптики -  Subdivision, DeliveryPoint, Subdivision, Clients

// Администрирование ролей - Group

// Доступ к истории заказов:
//  Subdivision, DeliveryPoint, OrderRecipes

// Каталог:
// Nomenclatures, Segments

// Все permissions на страничке /roles
// acc_contact: false
// acc_directory: 0
// acc_fin_info: false
// acc_main_page: false
// acc_order_history: false
// acc_order_wrh_les: false
// acc_orders_rx: false
// acc_polls: false
// client_adm: 0
// clients_employee: false
// emp_adm: true
// feedback: 0
// global_role: 1
// mrk_prm: 0
// price_vis_order: false
// profile_adm: 0
// rec_price_vis: false
// role_adm: true
// show_client: false
// warehouse_vis: 0

// const allPermissions = [
//   "add_clients",
//   "change_clients",
//   "delete_clients",
//   "view_clients",
//   "add_clientsemployees",
//   "change_clientsemployees",
//   "delete_clientsemployees",
//   "view_clientsemployees",
//   "add_notifications",
//   "change_notifications",
//   "delete_notifications",
//   "view_notifications",
//   "add_notificationsread",
//   "change_notificationsread",
//   "delete_notificationsread",
//   "view_notificationsread",
//   "add_permission",
//   "change_permission",
//   "delete_permission",
//   "view_permission",
//   "add_rolepermissions",
//   "change_rolepermissions",
//   "delete_rolepermissions",
//   "view_rolepermissions",
//   "add_roles",
//   "change_roles",
//   "delete_roles",
//   "view_roles",
//   "add_segmentvisibility",
//   "change_segmentvisibility",
//   "delete_segmentvisibility",
//   "view_segmentvisibility",
//   "add_segmentvisibilitytype",
//   "change_segmentvisibilitytype",
//   "delete_segmentvisibilitytype",
//   "view_segmentvisibilitytype",
//   "change_subdivision",
//   "delete_subdivision",
//   "view_subdivision",
//   "add_userroles",
//   "change_userroles",
//   "delete_userroles",
//   "view_userroles",
//   "add_users",
//   "change_users",
//   "delete_users",
//   "view_users",
//   "add_logentry",
//   "change_logentry",
//   "delete_logentry",
//   "view_logentry",
//   "add_group",
//   "change_group",
//   "delete_group",
//   "view_group",
//   "add_permission",
//   "change_permission",
//   "delete_permission",
//   "view_permission",
//   "add_user",
//   "change_user",
//   "delete_user",
//   "view_user",
//   "add_addresses",
//   "change_addresses",
//   "delete_addresses",
//   "view_addresses",
//   "view_balances",
//   "add_clients",
//   "change_clients",
//   "delete_clients",
//   "view_clients",
//   "add_clientsemployees",
//   "change_clientsemployees",
//   "delete_clientsemployees",
//   "view_clientsemployees",
//   "add_deliveryaddresses",
//   "change_deliveryaddresses",
//   "delete_deliveryaddresses",
//   "view_deliveryaddresses",
//   "add_deliverypoint",
//   "change_deliverypoint",
//   "delete_deliverypoint",
//   "view_deliverypoint",
//   "add_discount",
//   "change_discount",
//   "delete_discount",
//   "view_discount",
//   "add_materials",
//   "change_materials",
//   "delete_materials",
//   "view_materials",
//   "change_nomenclatures",
//   "delete_nomenclatures",
//   "view_nomenclatures",
//   "change_nomenclaturespecifications",
//   "delete_nomenclaturespecifications",
//   "view_nomenclaturespecifications",
//   "change_nomenclaturesrx",
//   "delete_nomenclaturesrx",
//   "view_nomenclaturesrx",
//   "add_notifications",
//   "change_notifications",
//   "delete_notifications",
//   "view_notifications",
//   "add_notificationsread",
//   "change_notificationsread",
//   "delete_notificationsread",
//   "view_notificationsread",
//   "view_orderitemsrx",
//   "view_orderrecipes",
//   "change_orders",
//   "delete_orders",
//   "view_orders",
//   "change_ordersrx",
//   "delete_ordersrx",
//   "view_ordersrx",
//   "add_paymentschedule",
//   "change_paymentschedule",
//   "delete_paymentschedule",
//   "view_paymentschedule",
//   "add_permission",
//   "change_permission",
//   "delete_permission",
//   "view_permission",
//   "add_promocodes",
//   "change_promocodes",
//   "delete_promocodes",
//   "view_promocodes",
//   "add_promotions",
//   "change_promotions",
//   "delete_promotions",
//   "view_promotions",
//   "add_propertyvalues",
//   "change_propertyvalues",
//   "delete_propertyvalues",
//   "view_propertyvalues",
//   "add_propertyvaluesrx",
//   "change_propertyvaluesrx",
//   "delete_propertyvaluesrx",
//   "view_propertyvaluesrx",
//   "add_rolepermissions",
//   "change_rolepermissions",
//   "delete_rolepermissions",
//   "view_rolepermissions",
//   "add_roles",
//   "change_roles",
//   "delete_roles",
//   "view_roles",
//   "add_segments",
//   "change_segments",
//   "delete_segments",
//   "view_segments",
//   "add_segmentsrx",
//   "change_segmentsrx",
//   "delete_segmentsrx",
//   "view_segmentsrx",
//   "add_segmentvisibility",
//   "change_segmentvisibility",
//   "delete_segmentvisibility",
//   "view_segmentvisibility",
//   "add_segmentvisibilitytype",
//   "change_segmentvisibilitytype",
//   "delete_segmentvisibilitytype",
//   "view_segmentvisibilitytype",
//   "change_stashes",
//   "delete_stashes",
//   "view_stashes",
//   "change_subdivision",
//   "delete_subdivision",
//   "view_subdivision",
//   "add_transportcompanies",
//   "change_transportcompanies",
//   "delete_transportcompanies",
//   "view_transportcompanies",
//   "add_userroles",
//   "change_userroles",
//   "delete_userroles",
//   "view_userroles",
//   "add_users",
//   "change_users",
//   "delete_users",
//   "view_users",
//   "add_contenttype",
//   "change_contenttype",
//   "delete_contenttype",
//   "view_contenttype",
//   "add_session",
//   "change_session",
//   "delete_session",
//   "view_session",
// ]
