type FilterName =
  | "authors"
  | "delivery_points"
  | "triggers"
  | "ordering"
  | "created_at_before"
  | "created_at_after"

export default {
  setEventHistory(_state: any, events: any) {
    _state.eventHistory = events
  },
  setEventFilters(_state: any, filters: any) {
    _state.eventHistoryFilters = filters
  },
  setEventHistoryFilter(
    _state: any,
    param: {
      filterName: FilterName
      value: any
    }
  ) {
    switch (param.filterName) {
      case "authors":
      case "delivery_points":
      case "triggers":
        _state.eventHistoryParams[param.filterName] = [...param.value]
        break
      case "ordering":
      case "created_at_before":
      case "created_at_after":
        _state.eventHistoryParams[param.filterName] = param.value
        break
      default:
        throw new Error(`Filter name ${param.filterName} is not supported`)
    }
    _state.pagination.current = 1
  },
  setCount(_state: any, count: number) {
    _state.pagination.total = count
  },
  setCurrentPage(_state: any, page: number) {
    _state.pagination.current = page
  },
  setRange(_state: any, params: any) {
    _state.range = params
  },
  resetFilters(_state: any) {
    _state.eventHistoryParams.created_at_before = ""
    _state.eventHistoryParams.created_at_after = ""
    _state.eventHistoryParams.authors = []
    _state.eventHistoryParams.delivery_points = []
    _state.eventHistoryParams.triggers = []
    _state.pagination.current = 1
    _state.range = null
  },
}
