export default {
  access: null,
  refresh: null,
  expires: null,
  permissions: {},
  refreshTokenExpires: null,
  code: null,
  status: "",
  error: false,
  errorText: "",
  user: {},
  authCopy: null,
  changedAccount: false,
}
