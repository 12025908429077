import {apiClient} from "@/api/setupApi"

const POST_RECLAMATION = `v1/users/claim`
const GET_RECLAMATION_LIST = `v1/users/claim`
const GET_RECLAMATION_GPM_LIST = `v1/users/claim/gpm`
const POST_REFERENCE_NUMBER = `v1/users/claim/reference`
const GET_ORDERS_RX_SHORT = `v1/cart/get-orders/rx/short`
const POST_GPM_RECLAMATION = `v1/users/claim/gpm`
const EDIT_GPM_RECLAMATION = `v1/users/claim/gpm`

export default {
  postReclamation(data: any) {
    return apiClient.post(POST_RECLAMATION, data)
  },
  postReferenceNumber(data: object): Promise<any> {
    return apiClient.post(POST_REFERENCE_NUMBER, data)
  },
  getReclamationList(params: any): Promise<any> {
    return apiClient.get(GET_RECLAMATION_LIST, {params})
  },
  getReclamationGPMList(params: any): Promise<any> {
    return apiClient.get(GET_RECLAMATION_GPM_LIST, {params})
  },
  editGPMReclamation(data: any): Promise<any> {
    return apiClient.put(`${EDIT_GPM_RECLAMATION}/${data.id}`, data.form)
  },
  getOrdersRxShort(): Promise<any> {
    return apiClient.get(GET_ORDERS_RX_SHORT)
  },
  postGPMReclamation(data: object): Promise<any> {
    return apiClient.post(POST_GPM_RECLAMATION, data)
  },
}
