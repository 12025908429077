import {apiClient} from "@/api/setupApi"

const BASE_VUE_APP_URL = process.env.VUE_APP_URL

const GET_HISTORY_ORDER = `${BASE_VUE_APP_URL}/api/api/v1/cart/orders`
const GET_HISTORY_ORDER_RX = `${BASE_VUE_APP_URL}/api/api/v1/cart/rx/orders?page=`

interface Params {
  [key: string]: string
}
export default {
  getHistoryOrders(data: any) {
    const params: Params = {
      page: data.page || 1,
    }

    if (data.type) {
      params.type = data.type
    }

    for (const key in data.values) {
      if (data.values[key]) {
        params[key] = data.values[key]
      }
    }

    return apiClient.get(GET_HISTORY_ORDER, {
      params: params,
      baseURL: BASE_VUE_APP_URL,
    })
  },
}
