const lettersRegex = /[A-Za-z]/
const lowLettersRegex = /[a-z]/
const capLettersRegex = /[A-Z]/
const numbersRegex = /[0-9]/
const specialSymbolsRegex = /[!#$%&*,.@^_?]/
const email =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/

const rules: {[key: string]: RegExp} = {
  letter: lettersRegex,
  lowLetters: lowLettersRegex,
  capLetters: capLettersRegex,
  number: numbersRegex,
  special: specialSymbolsRegex,
  email,
}
// type Validate = (rule: string, str: string) => boolean;
export const checkRule = (rule: string, str: string): boolean =>
  rules[rule].test(str)

export const validate = (ruleSet: any[], value: string): boolean =>
  ruleSet.map((rule) => checkRule(rule, value)).every((el) => el)
